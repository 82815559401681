import React, { useState, useEffect } from 'react';
import NavBar from './NavBar';
import Sidebar from './Sidebar'; // Importa el componente Sidebar
import './Main.css'; // Asegúrate de importar el CSS correctamente
import Chat from './Chat'; // Importa el componente Chat
import ClientsTable from './ClientsTable'; // Importa el nuevo componente ClientsTable
import KanbanBoard from './KanbanBoard'; // Importa el nuevo componente KanbanBoard

function Main() {
    const [session, setSession] = useState({
        username: '',
        email: '',
        all_users: []
    });

    useEffect(() => {
        // Aquí harías una llamada para obtener los datos de la sesión
        fetch('/get_session_data')
            .then(response => response.json())
            .then(data => setSession(data));
    }, []);

    const changeUser = (email) => {
        fetch('/change_user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === "success") {
                window.location.reload();
            } else {
                alert('Error al cambiar de usuario. Por favor, inténtalo de nuevo.');
            }
        })
        .catch(error => console.error('Fetch error:', error));
    };

    const toggleSidebar = () => {
        const sidebar = document.getElementById('sidebar');
        const mainContent = document.getElementById('main-content');
        const toggleBtn = document.getElementById('toggle-btn');

        sidebar.classList.toggle('collapsed');
        mainContent.classList.toggle('expanded');
        toggleBtn.classList.toggle('collapsed');
    };

    return (
        <div className="main-container">
            <NavBar session={session} changeUser={changeUser} />
            <Sidebar session={session} changeUser={changeUser} toggleSidebar={toggleSidebar} /> {/* Aquí se pasa changeUser */}
            <div className="main-content expanded" id="main-content">
                <section className="main-clients-section">
                    <h2 className="main-section-title">Clientes</h2>
                    <ClientsTable />
                </section>
                <section className="main-kanban-section">
                    <h2 className="main-section-title">Ventas</h2>
                    <KanbanBoard />
                </section>
                <Chat />
            </div>
        </div>
    );
}

export default Main;
