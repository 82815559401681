import React, { createContext, useState, useContext, useEffect } from 'react';

const ClientsContext = createContext();

export const useClients = () => {
    return useContext(ClientsContext);
};

export const ClientsProvider = ({ children }) => {
    const [clients, setClients] = useState([]);
    const [sales, setSales] = useState([]);
    const [showSaleDetailsModal, setShowSaleDetailsModal] = useState(false); // Estado para mostrar el modal de detalles de la venta
    const [saleDetails, setSaleDetails] = useState(null); // Estado para almacenar los detalles de la venta

    useEffect(() => {
        // Fetch initial clients data
        fetch('/get_all_clients_data')
            .then(response => response.json())
            .then(data => {
                const clientsList = Object.keys(data).map(clientId => ({
                    id: clientId,
                    company_name: data[clientId].company_name || 'N/A',
                    contact_name: data[clientId].contact_name || 'N/A',
                    email: data[clientId].email || 'N/A',
                    position: data[clientId].position || 'N/A',
                    description: data[clientId].description || 'N/A',
                    responsable: data[clientId].responsable || 'N/A',
                    color: data[clientId].color,
                    has_taken: data[clientId].has_taken
                }));
                setClients(clientsList);
            })
            .catch(error => console.error('Error fetching clients:', error));

        // Fetch initial sales data
        fetch('/get_sales')
            .then(response => response.json())
            .then(data => setSales(data))
            .catch(error => console.error('Error fetching sales:', error));
    }, []);

    const deleteClientAndSales = (clientId) => {
        setClients(prevClients => prevClients.filter(client => client.id !== clientId));
        setSales(prevSales => prevSales.filter(sale => sale.client_id !== clientId));
    };

    return (
        <ClientsContext.Provider value={{ clients, setClients, sales, setSales, deleteClientAndSales, showSaleDetailsModal, setShowSaleDetailsModal, saleDetails, setSaleDetails }}>
            {children}
        </ClientsContext.Provider>
    );
};
