import React, { useEffect } from 'react';
import './Sidebar.css';

const Sidebar = ({ toggleSidebar }) => {

    useEffect(() => {
        fetch('/is_admin')
            .then(response => response.json())
            .then(data => {
                if (data.isAdmin) {
                    const adminLink = document.createElement('a');
                    adminLink.href = "/users";
                    adminLink.innerText = "Configuración de Usuarios";
                    adminLink.className = "admin-link";
                    document.getElementById('sidebar').appendChild(adminLink);
                }
            });
    }, []);

    return (
        <div>
            <div className="sidebar collapsed" id="sidebar">
                <a href="/main">Inicio</a>
                <a href="/statistics">Estadísticas</a>
            </div>
            <button className="toggle-btn collapsed" id="toggle-btn" onClick={toggleSidebar}></button>
        </div>
    );
};

export default Sidebar;
