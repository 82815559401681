import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ClientsProvider } from './ClientsContext';
import Welcome from './components/Welcome';
import Main from './components/Main';
import Statistics from './components/Statistics';
import Users from './components/Users';
import ClientsTable from './components/ClientsTable';
import KanbanBoard from './components/KanbanBoard';

function App() {
    return (
        <ClientsProvider>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<Welcome />} />
                        <Route path="/main" element={<Main />} />
                        <Route path="/statistics" element={<Statistics />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/clients" element={<ClientsTable />} />
                        <Route path="/kanban" element={<KanbanBoard />} />
                    </Routes>
                </div>
            </Router>
        </ClientsProvider>
    );
}

export default App;
