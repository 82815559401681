import React, { useState, useEffect, useRef } from 'react';
import './Chat.css';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [messageContent, setMessageContent] = useState('');
  const chatBoxRef = useRef(null);

  useEffect(() => {
    // Load messages from local storage or server
    const storedMessages = JSON.parse(localStorage.getItem('chatHistory')) || [];
    setMessages(storedMessages);

    // Fetch messages from server
    fetch('/get_messages')
      .then((response) => response.json())
      .then((data) => setMessages(data.messages));
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (!messageContent.trim()) return;

    const newMessage = { role: 'user', content: messageContent };
    setMessages([...messages, newMessage]);

    fetch('/send_message', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `message=${encodeURIComponent(messageContent)}`,
    })
      .then((response) => response.json())
      .then((data) => {
        setMessages([...messages, newMessage, { role: 'assistant', content: data.message }]);
      });

    setMessageContent('');
  };

  const scrollToBottom = () => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  };

  const handleToggleChat = () => {
    document.querySelector('.chat-container').classList.toggle('open');
  };

  return (
    <>
      <div className="open-chat" onClick={handleToggleChat}>
        Chat Eva
      </div>
      <div className="chat-container">
        <div className="chat-header" onClick={handleToggleChat}>
          Chat
        </div>
        <div className="border rounded p-3 bg-white" id="chat-box" ref={chatBoxRef}>
          {messages.map((message, index) => (
            <div
              key={index}
              className={message.role === 'user' ? 'user-message' : 'bot-message'}
            >
              {message.content}
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center mt-3" id="message-input">
          <form onSubmit={handleSendMessage} className="d-flex" id="chatForm">
            <input
              className="form-control"
              id="userMessageInput"
              name="message"
              placeholder="Escribe tu mensaje..."
              required
              type="text"
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
            />
            <button className="btn btn-primary" type="submit">
              Enviar
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Chat;
