import React, { useEffect, useState } from 'react';
import './Statistics.css';
import { Chart } from 'chart.js/auto';
import Sidebar from './Sidebar';
import './Sidebar.css';

const Statistics = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [salesData, setSalesData] = useState({
        service_sales: {},
        stage_sales: {},
        monthly_revenue: {},
        sales_status: {},
        monthly_forecast: {},
        meeting_statistics: {} // Añadir aquí
    });

    let serviceSalesChart, stageSalesChart, monthlyRevenueChart, salesStatusChart, monthlyForecastChart, meetingStatisticsChart;

    useEffect(() => {
        fetch('/is_admin')
            .then(response => response.json())
            .then(data => {
                setIsAdmin(data.isAdmin);
                if (data.isAdmin) {
                    fetch('/get_responsibles')
                        .then(response => response.json())
                        .then(responsibles => {
                            const responsibleSelect = document.getElementById('responsible');
                            responsibles.forEach(responsible => {
                                const option = document.createElement('option');
                                option.value = responsible;
                                option.text = responsible;
                                responsibleSelect.appendChild(option);
                            });
                        });
                }
            });
        loadSalesData();
        loadMeetingStatistics(); // Añadir aquí
    }, []);

    const loadSalesData = (startDate, endDate, responsible) => {
        const params = new URLSearchParams();
        if (startDate) params.append('start_date', startDate);
        if (endDate) params.append('end_date', endDate);
        if (responsible) params.append('responsible', responsible);

        fetch(`/get_sales_statistics?${params.toString()}`)
            .then(response => response.json())
            .then(data => {
                setSalesData(data);
                updateChart(serviceSalesChart, Object.keys(data.service_sales), Object.values(data.service_sales));
                updateStageSalesChart(stageSalesChart, data.stage_sales);
                updateMonthlyChart(monthlyRevenueChart, data.monthly_revenue);
                updateMonthlyChart(monthlyForecastChart, data.monthly_forecast, 'Proyección de Facturación');
                updateChart(salesStatusChart, Object.keys(data.sales_status), Object.values(data.sales_status));
            });
    };

    const loadMeetingStatistics = () => {
        fetch('/get_meeting_statistics')
            .then(response => response.json())
            .then(data => {
                setSalesData(prevState => ({ ...prevState, meeting_statistics: data }));
                updateChart(meetingStatisticsChart, Object.keys(data), Object.values(data));
            });
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
    };

    const updateChart = (chart, labels, data) => {
        chart.data.labels = labels;
        chart.data.datasets[0].data = data;
        chart.update();
    };

    const updateStageSalesChart = (chart, data) => {
        const stageLabels = new Set();
        const stageDatasets = {};

        for (const [responsable, stages] of Object.entries(data)) {
            for (const stage of Object.keys(stages)) {
                stageLabels.add(stage);
            }
        }

        for (const [responsable, stages] of Object.entries(data)) {
            const dataset = {
                label: responsable,
                data: [],
                backgroundColor: getRandomColor(),
                borderColor: getRandomColor(),
                borderWidth: 1
            };
            for (const stage of stageLabels) {
                dataset.data.push(stages[stage] || 0);
            }
            stageDatasets[responsable] = dataset;
        }

        chart.data.labels = Array.from(stageLabels);
        chart.data.datasets = Object.values(stageDatasets);
        chart.update();
    };

    const updateMonthlyChart = (chart, data, label = 'Total a Facturar') => {
        const months = ['2024-01', '2024-02', '2024-03', '2024-04', '2024-05', '2024-06', '2024-07', '2024-08', '2024-09', '2024-10', '2024-11', '2024-12'];
        const values = months.map(month => data[month] || 0);

        chart.data.labels = months;
        chart.data.datasets[0].data = values;
        chart.data.datasets[0].label = label;
        chart.update();
    };

    const getRandomColor = () => {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgba(${r}, ${g}, ${b}, 0.2)`;
    };

    useEffect(() => {
        const commonChartOptions = {
            responsive: true,
            maintainAspectRatio: false, // Esto permite que los gráficos se ajusten al contenedor
            scales: {
                y: {
                    beginAtZero: true
                }
            },
            plugins: {
                title: {
                    display: true
                }
            }
        };

        serviceSalesChart = new Chart(document.getElementById('serviceSalesChart'), {
            type: 'bar',
            data: {
                labels: [],
                datasets: [{
                    label: 'Número de Ventas',
                    data: [],
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1
                }]
            },
            options: {
                ...commonChartOptions,
                plugins: {
                    ...commonChartOptions.plugins,
                    title: {
                        ...commonChartOptions.plugins.title,
                        text: 'Gráfico de Barras: Ventas por Servicio'
                    }
                }
            }
        });

        stageSalesChart = new Chart(document.getElementById('stageSalesChart'), {
            type: 'bar',
            data: {
                labels: [],
                datasets: []
            },
            options: {
                ...commonChartOptions,
                plugins: {
                    ...commonChartOptions.plugins,
                    title: {
                        ...commonChartOptions.plugins.title,
                        text: 'Gráfico de Barras Apiladas: Etapas de Ventas por Responsable'
                    }
                }
            }
        });

        monthlyRevenueChart = new Chart(document.getElementById('monthlyRevenueChart'), {
            type: 'line',
            data: {
                labels: [],
                datasets: [{
                    label: 'Total a Facturar',
                    data: [],
                    backgroundColor: 'rgba(153, 102, 255, 0.2)',
                    borderColor: 'rgba(153, 102, 255, 1)',
                    borderWidth: 1,
                    fill: false
                }]
            },
            options: {
                ...commonChartOptions,
                plugins: {
                    ...commonChartOptions.plugins,
                    title: {
                        ...commonChartOptions.plugins.title,
                        text: 'Gráfico de Líneas: Ingresos Totales por Mes'
                    }
                }
            }
        });

        monthlyForecastChart = new Chart(document.getElementById('monthlyForecastChart'), {
            type: 'line',
            data: {
                labels: [],
                datasets: [{
                    label: 'Proyección de Facturación',
                    data: [],
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                    fill: false
                }]
            },
            options: {
                ...commonChartOptions,
                plugins: {
                    ...commonChartOptions.plugins,
                    title: {
                        ...commonChartOptions.plugins.title,
                        text: 'Gráfico de Líneas: Proyección de Facturación Mensual'
                    }
                }
            }
        });

        salesStatusChart = new Chart(document.getElementById('salesStatusChart'), {
            type: 'pie',
            data: {
                labels: [],
                datasets: [{
                    label: 'Estados de las Ventas',
                    data: [],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                ...commonChartOptions,
                plugins: {
                    ...commonChartOptions.plugins,
                    title: {
                        ...commonChartOptions.plugins.title,
                        text: 'Gráfico de Tarta: Distribución de Ventas por Estado'
                    }
                }
            }
        });

        meetingStatisticsChart = new Chart(document.getElementById('meetingStatisticsChart'), {
            type: 'bar',
            data: {
                labels: [],
                datasets: [{
                    label: 'Número de Reuniones',
                    data: [],
                    backgroundColor: 'rgba(255, 205, 86, 0.2)',
                    borderColor: 'rgba(255, 205, 86, 1)',
                    borderWidth: 1
                }]
            },
            options: {
                ...commonChartOptions,
                plugins: {
                    ...commonChartOptions.plugins,
                    title: {
                        ...commonChartOptions.plugins.title,
                        text: 'Gráfico de Barras: Reuniones Programadas por Usuario'
                    }
                }
            }
        });

        loadSalesData();
        loadMeetingStatistics(); // Cargar las estadísticas de reuniones
    }, []);

    const toggleSidebar = () => {
        document.getElementById('sidebar').classList.toggle('collapsed');
        document.getElementById('toggle-btn').classList.toggle('collapsed');
    };

    return (
        <div className="statistics-container">
            <Sidebar toggleSidebar={toggleSidebar} />
            <div className="statistics-content">
                <h1 className="statistics-header"><i className="fas fa-chart-bar"></i> Estadísticas de Ventas</h1>
                
                <div className="statistics-filter-row">
                    <div className="filter-item">
                        <label htmlFor="start-date">Fecha de Inicio:</label>
                        <input type="date" id="start-date" className="form-control" />
                    </div>
                    <div className="filter-item">
                        <label htmlFor="end-date">Fecha de Fin:</label>
                        <input type="date" id="end-date" className="form-control" />
                    </div>
                </div>
                {isAdmin && (
                    <div className="statistics-filter-row" id="responsible-filter">
                        <div className="filter-item">
                            <label htmlFor="responsible">Responsable:</label>
                            <select id="responsible" className="form-control">
                                <option value="">Todos</option>
                            </select>
                        </div>
                    </div>
                )}
                <div className="text-center">
                    <button id="filter-button" className="statistics-filter-button mt-3" onClick={() => {
                        const startDate = document.getElementById('start-date').value;
                        const endDate = document.getElementById('end-date').value;
                        const responsible = document.getElementById('responsible').value;
                        loadSalesData(startDate, endDate, responsible);
                    }}>Filtrar</button>
                </div>            

                {/* KPIs */}
                <div className="kpi-row">
                    <div className="kpi-item">
                        <h2 id="total-sales">{Object.values(salesData.service_sales).reduce((a, b) => a + b, 0)}</h2>
                        <p>Total de Ventas</p>
                    </div>
                    <div className="kpi-item">
                        <h2 id="closed-sales">{salesData.sales_status['Cerrado'] || 0}</h2>
                        <p>Ventas Cerradas</p>
                    </div>
                    <div className="kpi-item">
                        <h2 id="total-revenue">{formatCurrency(Object.values(salesData.monthly_revenue).reduce((a, b) => a + b, 0))}</h2>
                        <p>Ingresos Totales</p>
                    </div>
                </div>
                
                {/* Gráficos */}
                <div className="chart-row">
                    <div className="chart-item">
                        <canvas id="serviceSalesChart"></canvas>
                    </div>
                    <div className="chart-item">
                        <canvas id="stageSalesChart"></canvas>
                    </div>
                </div>
                <div className="chart-row">
                    <div className="chart-item">
                        <canvas id="monthlyRevenueChart"></canvas>
                    </div>
                    <div className="chart-item">
                        <canvas id="monthlyForecastChart"></canvas>
                    </div>
                </div>
                <div className="chart-row">
                    <div className="chart-item">
                        <canvas id="salesStatusChart"></canvas>
                    </div>
                </div>
                <div className="chart-row">
                    <div className="chart-item">
                        <canvas id="meetingStatisticsChart"></canvas> {/* Añadir este canvas */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Statistics;
