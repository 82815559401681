import React, { useState, useEffect, useRef, useCallback } from 'react';
import './KanbanBoard.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { memo } from 'react';
import './DarkThemeModals.css';
import { useClients } from '../ClientsContext';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCalendar, faClipboard } from '@fortawesome/free-solid-svg-icons';
import io from 'socket.io-client';

const socket = io();

const KanbanBoard = () => {
  const { sales, setSales, showSaleDetailsModal, setShowSaleDetailsModal, saleDetails, setSaleDetails } = useClients();
  const [isAdmin, setIsAdmin] = useState(false);
  const { clients, setClients } = useClients();
  const [newSale, setNewSale] = useState({
    client: '',
    solicitudes: [{ service: 'Reclutamiento', solicitud: '', observaciones: '' }]
  });
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showProposalModal, setShowProposalModal] = useState(false);
  const [showSendProposalModal, setShowSendProposalModal] = useState(false);
  const [currentSaleId, setCurrentSaleId] = useState(null);
  const [serviceName, setServiceName] = useState('');
  const [pdfPath, setPdfPath] = useState('');
  const [proposalFilePath, setProposalFilePath] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientName, setClientName] = useState('');
  const [showOutsourcingModal, setShowOutsourcingModal] = useState(false);
  const [showReclutamientoModal, setShowReclutamientoModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [showServiceOrderModal, setShowServiceOrderModal] = useState(false);
  const [showAddSaleModal, setShowAddSaleModal] = useState(false);
  const [showCloseSaleModal, setShowCloseSaleModal] = useState(false);
  const [closeSaleStatus, setCloseSaleStatus] = useState('');
  const [originalStage, setOriginalStage] = useState('');
  const [rentaBrutaMensual, setRentaBrutaMensual] = useState('');
  const [showSendServiceOrderModal, setShowSendServiceOrderModal] = useState(false);
  const [currentServiceName, setCurrentServiceName] = useState('');
  const [serviceOrderFilePath, setServiceOrderFilePath] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [saleToDelete, setSaleToDelete] = useState(null);
  const [showManualSaleInput, setShowManualSaleInput] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [users, setUsers] = useState([]);
  const [visibleSales, setVisibleSales] = useState([]); // Ventas visibles en pantalla
  const [hasMore, setHasMore] = useState(true); // Controla si hay más tarjetas para cargar
  const [loadingMore, setLoadingMore] = useState(false); // Controla el estado de carga
  const itemsPerPage = 30; // Cantidad de tarjetas a cargar por vez
  const [selectedContact, setSelectedContact] = useState('');

  useEffect(() => {
    loadKanbanBoard();
    checkAdminStatus();
    initializeWebSocket();
    fetchUsers();
  }, []);

  const handleWorkModeChange = (e) => {
    const workMode = e.target.value;
    const colacionField = document.getElementById('colacionField');
    const movilizacionField = document.getElementById('movilizacionField');
    const asignacionTeletrabajoField = document.getElementById('asignacionTeletrabajoField');

    if (workMode === 'Teletrabajo') {
      colacionField.style.display = 'block';
      movilizacionField.style.display = 'none';
      asignacionTeletrabajoField.style.display = 'block';
      document.getElementById('movilizacionInput').value = '0';
    } else if (workMode === 'Presencial') {
      colacionField.style.display = 'block';
      movilizacionField.style.display = 'block';
      asignacionTeletrabajoField.style.display = 'none';
      document.getElementById('asignacionTeletrabajoInput').value = '0';
    } else if (workMode === 'Híbrido') {
      colacionField.style.display = 'block';
      movilizacionField.style.display = 'block';
      asignacionTeletrabajoField.style.display = 'block';
    } else {
      colacionField.style.display = 'none';
      movilizacionField.style.display = 'none';
      asignacionTeletrabajoField.style.display = 'none';
      document.getElementById('colacionInput').value = '0';
      document.getElementById('movilizacionInput').value = '0';
      document.getElementById('asignacionTeletrabajoInput').value = '0';
    }
  };

  const loadKanbanBoard = async () => {
    try {
      const response = await fetch('/get_sales');
      const data = await response.json();
      setSales(data);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const checkAdminStatus = async () => {
    try {
      const response = await fetch('/is_admin');
      const data = await response.json();
      setIsAdmin(data.isAdmin);
    } catch (error) {
      console.error('Error checking admin status:', error);
    }
  };

  const initializeWebSocket = () => {
    const socket = new WebSocket('ws://localhost:5000');
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.action === 'create' || data.action === 'update') {
        loadKanbanBoard();
      } else if (data.action === 'update_sale_responsable') {
        setSales(sales => sales.map(sale =>
          sale.sale_id === data.sale_id ? { ...sale, responsable: data.responsable } : sale
        ));
      }
    };
  };

  const handleStageChange = async (saleId, newStage) => {
    try {
      const response = await fetch('/update_sale_stage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `sale_id=${encodeURIComponent(saleId)}&new_stage=${encodeURIComponent(newStage)}`
      });
      const data = await response.json();
      console.log(data.message);
      const sale = sales.find(s => s.sale_id == saleId);

      if (newStage === 'Reunión inicial') {
        promptAndGenerateProposal(saleId, sale.service);
      } else if (newStage === 'Negociación') {
        if (sale.service === "Outsourcing") {
          setShowOutsourcingModal(true);
          setCurrentSaleId(saleId);
          setServiceName(sale.service);
        } else if (sale.service === "Reclutamiento") {
          setShowReclutamientoModal(true);
          setCurrentSaleId(saleId);
          setServiceName(sale.service);
        }
      } else if (newStage === 'Cierre') {
        setCurrentSaleId(saleId);
        setOriginalStage(sale.stage);
        setShowCloseSaleModal(true);
      }

      loadKanbanBoard();
    } catch (error) {
      console.error('Error updating sale stage:', error);
    }
  };

  const handleDragUpdate = (update) => {
    const trashBin = document.getElementById('trashBin');
    if (update.destination && update.destination.droppableId === 'trashBin') {
      trashBin.classList.add('dragging-over');
    } else {
      trashBin.classList.remove('dragging-over');
    }
  };

  const handleAddSale = async () => {
    try {
      const formData = new FormData();
      formData.append('client_id', newSale.client);
      newSale.solicitudes.forEach((solicitud, index) => {
        formData.append('service[]', solicitud.service);
        formData.append('solicitud[]', solicitud.solicitud);
        formData.append('observaciones[]', solicitud.observaciones);
      });
      const response = await fetch('/create_sale', {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      if (data.error) {
        alert(data.error);
        return;
      }
      data.sale_id.forEach((saleID, index) => {
        promptAndGenerateProposal(saleID, newSale.solicitudes[index].service);
      });
      loadKanbanBoard();
      resetNewSaleFields();
      setShowAddSaleModal(false);
    } catch (error) {
      console.error('Error creating sale:', error);
    }
  };

  const handleAddSolicitud = () => {
    setNewSale({
      ...newSale,
      solicitudes: [...newSale.solicitudes, { service: 'Reclutamiento', solicitud: '', observaciones: '' }]
    });
  };

  const handleRemoveSolicitud = (index) => {
    const newSolicitudes = [...newSale.solicitudes];
    newSolicitudes.splice(index, 1);
    setNewSale({ ...newSale, solicitudes: newSolicitudes });
  };

  const KanbanCard = ({ sale, index, handleCardDoubleClick }) => {
    const cardClass = sale.service === 'Outsourcing' ? 'outsourcing' : sale.service === 'Reclutamiento' ? 'reclutamiento' : '';
    const progress = sale.stage === 'Cierre' ? 100 : sale.stage === 'Negociación' ? 75 : sale.stage === 'Requerimiento' ? 50 : 25;
  
    return (
      <Draggable key={sale.sale_id} draggableId={sale.sale_id.toString()} index={index}>
        {(provided) => (
          <div
            className={`kanban-card ${cardClass}`}
            data-sale-id={sale.sale_id}
            onDoubleClick={() => handleCardDoubleClick(sale.sale_id)}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="kanban-card-header">
              <strong>Venta #{sale.sale_id}</strong>
              <span className="kanban-tag">{sale.priority}</span>
            </div>
            <div className="kanban-card-body">
              <p><FontAwesomeIcon icon={faUser} /> Cliente: {sale.client_name}</p>
              <p><FontAwesomeIcon icon={faClipboard} /> Solicitud: {sale.solicitud}</p>
              <p>Observaciones: {sale.observaciones}</p>
            </div>
            <div className="kanban-card-footer">
              <span><FontAwesomeIcon icon={faCalendar} /> Fecha: {new Date(sale.created_at).toLocaleDateString()}</span>
              <span id={`status-${sale.sale_id}`}>Estado: {sale.status}</span>
            </div>
            <div className="progress mt-2">
              <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  const stages = [
    'Reunión inicial',
    'Requerimiento',
    'Negociación',
    'Cierre',
    'Seguimiento'
  ];

  const handleFilter = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const query = new URLSearchParams(formData).toString();
    loadFilteredKanbanBoard(query);
  };

  const loadFilteredKanbanBoard = async (query) => {
    try {
      const response = await fetch(`/get_sales?${query}`);
      const data = await response.json();
      setSales(data);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const handleCardDoubleClick = async (saleId) => {
    try {
      const response = await fetch('/get_sales');
      const sales = await response.json();
      const sale = sales.find(s => s.sale_id == saleId);
      if (sale) {
        setSaleDetails(sale);
        setShowSaleDetailsModal(true);
        loadComments(saleId);
        loadSaleFiles(saleId);
      }
    } catch (error) {
      console.error('Error fetching sale details:', error);
    }
  };

  const handleCommentSubmit = async () => {
    const formData = new FormData();
    formData.append('sale_id', saleDetails.sale_id);
    formData.append('text', newComment);

    try {
      const response = await fetch('/add_comment', {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      if (data.message === 'Comment added successfully') {
        alert('Comentario agregado!');
        setNewComment('');
        loadComments(saleDetails.sale_id);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const loadComments = async (saleId) => {
    try {
      const response = await fetch(`/get_comments/${saleId}`);
      const comments = await response.json();
      setComments(comments);
      const isAdminResponse = await fetch('/is_admin');
      const isAdminData = await isAdminResponse.json();
      if (isAdminData.isAdmin) {
        document.querySelectorAll('.btn-outline-danger').forEach(btn => {
          btn.style.display = 'inline-block';
        });
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const showEditCommentInput = (commentId) => {
    const commentP = document.querySelector(`[data-comment-id="${commentId}"]`);
    const commentTextSpan = commentP.querySelector(".comment-text");
    const commentText = commentTextSpan.textContent;
    commentTextSpan.innerHTML = `<input type="text" class="form-control" id="editCommentInput${commentId}" value="${commentText}">`;
    commentTextSpan.innerHTML += `<button className="btn btn-sm btn-success save-comment-edit" data-comment-id="${commentId}" onClick="saveCommentEdit(${commentId})">Guardar</button>`;
  };

  const saveCommentEdit = (commentId) => {
    const editInput = document.getElementById(`editCommentInput${commentId}`);
    const newText = editInput.value;
    const commentDiv = editInput.parentElement;
    commentDiv.innerHTML = newText;
    editComment(commentId, newText);
  };

  const editComment = async (commentId, newText) => {
    const formData = new FormData();
    formData.append('comment_id', commentId);
    formData.append('text', newText);

    try {
      const response = await fetch('/edit_comment', {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      if (data.message === 'Comment updated successfully') {
        alert('Comentario actualizado!');
        loadComments(saleDetails.sale_id);
      }
    } catch (error) {
      console.error('Error updating comment:', error);
    }
  };

  const deleteComment = async (commentId) => {
    const confirmation = window.confirm("¿Estás seguro de que quieres eliminar este comentario?");
    if (confirmation) {
      try {
        const response = await fetch(`/delete_comment/${commentId}`, {
          method: 'DELETE',
        });
        const data = await response.json();
        if (data.message === 'Comment deleted successfully') {
          alert('Comentario eliminado!');
          loadComments(saleDetails.sale_id);
        }
      } catch (error) {
        console.error('Error deleting comment:', error);
      }
    }
  };

  const loadSaleFiles = async (saleId) => {
    try {
      const response = await fetch(`/get_sale_files/${saleId}`);
      const files = await response.json();
      setFiles(files);
    } catch (error) {
      console.error('Error fetching sale files:', error);
    }
  };

  const handleFileUpload = async () => {
    const saleId = saleDetails.sale_id;
    const formData = new FormData();
    formData.append('sale_id', saleId);
    formData.append('saleFile', selectedFile);

    try {
      const response = await fetch('/upload_sale_file', {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      if (data.message === 'File uploaded successfully') {
        alert('Archivo subido exitosamente!');
        addAutomaticComment("Se ha subido un archivo.");
        loadSaleFiles(saleId);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const addAutomaticComment = async (text) => {
    const formData = new FormData();
    formData.append('sale_id', saleDetails.sale_id);
    formData.append('text', text);

    try {
      const response = await fetch('/add_comment', {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      if (data.message === 'Comment added successfully') {
        loadComments(saleDetails.sale_id);
      }
    } catch (error) {
      console.error('Error adding automatic comment:', error);
    }
  };

  const deleteSaleFile = async (fileId) => {
    const confirmation = window.confirm("¿Estás seguro de que quieres eliminar este archivo?");
    if (confirmation) {
      try {
        const response = await fetch(`/delete_sale_file/${fileId}`, {
          method: 'DELETE',
        });
        const data = await response.json();
        if (data.message === 'File deleted successfully') {
          alert('Archivo eliminado exitosamente!');
          addAutomaticComment("Se ha eliminado un archivo.");
          loadSaleFiles(saleDetails.sale_id);
        }
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    }
  };

  const handleModalClose = () => {
    setShowSaleDetailsModal(false);
    setSaleDetails(null);
    setComments([]);
    setFiles([]);
  };

  const promptAndGenerateProposal = (saleId, service) => {
    setCurrentSaleId(saleId);
    setServiceName(service);
    setShowProposalModal(true);
  };

  useEffect(() => {
    if (showProposalModal && currentSaleId) {
      fetchContacts(currentSaleId);
    }
  }, [showProposalModal, currentSaleId]);

  const generateProposal = async () => {
    try {
      setShowProposalModal(false);
      setShowLoadingModal(true);
      startProgressBar();
      
      const response = await fetch('/generate_proposal', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sale_id: currentSaleId,
          service_name: serviceName,
          contact_id: selectedContact
        }),
      });
      const data = await response.json();
      toastr.success(data.message, 'Propuesta Generada');
      
      // Convertir la propuesta a Blob y crear una URL de objeto
      const proposalResponse = await fetch(data.pdf_path);
      const proposalBlob = await proposalResponse.blob();
      const filePath = URL.createObjectURL(proposalBlob);
      
      setPdfPath(data.pdf_path);
      setProposalFilePath(filePath); // Almacenar la ruta del archivo de la propuesta
      setClientEmail(data.client_email);
      setClientName(data.client_name);
      handleFileUploadWithPath(data.pdf_path, data.pdf_name, currentSaleId);
      promptAndSendProposal(data.pdf_path, data.client_email, data.client_name, currentSaleId);
    } catch (error) {
      toastr.error('Error generating proposal', 'Error');
      console.error('Error generating proposal:', error);
    } finally {
      setShowLoadingModal(false);
    }
  };

  const promptAndSendProposal = (pdfPath, clientEmail, clientName, saleId) => {
    setPdfPath(pdfPath);
    setClientEmail(clientEmail);
    setClientName(clientName);
    setCurrentSaleId(saleId);
    setShowSendProposalModal(true);
  };

  const sendProposal = async () => {
    try {
        // Cierra el modal de envío de propuesta y muestra el modal de carga
        setShowSendProposalModal(false);
        setShowLoadingModal(true);
        startProgressBar(); // Inicia la barra de progreso de carga

        // Realiza la solicitud para enviar la propuesta
        const response = await fetch('/send_proposal', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                sale_id: currentSaleId,
                pdf_path: pdfPath,
                client_email: clientEmail,
                client_name: clientName
            }),
        });

        // Verifica si la respuesta es exitosa
        if (!response.ok) {
            throw new Error('Error sending proposal');
        }

        const data = await response.json();

        // Muestra un mensaje de éxito
        toastr.success(data.message, 'Propuesta Enviada');
    } catch (error) {
        // Muestra un mensaje de error y lo registra en la consola
        toastr.error(error.message || 'Error sending proposal', 'Error');
        console.error('Error sending proposal:', error);
    } finally {
        // Siempre oculta el modal de carga, incluso si hubo un error
        setShowLoadingModal(false);
    }
};

  

  const handleFileUploadWithPath = async (filePath, fileName, saleId) => {
    const formData = new FormData();
    formData.append('sale_id', saleId);
    formData.append('file_path', filePath);
    formData.append('file_name', fileName);

    try {
      const response = await fetch('/upload_sale_file', {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      if (data.message === 'File uploaded successfully') {
        alert('Propuesta adjuntada exitosamente!');
        addAutomaticComment("Se ha adjuntado la propuesta generada.");
        loadSaleFiles(saleId);
      }
    } catch (error) {
      console.error('Error uploading existing file:', error);
    }
  };

  useEffect(() => {
    if (showOutsourcingModal && currentSaleId) {
      fetchContacts(currentSaleId);
    }
  }, [showOutsourcingModal, currentSaleId]);

  const handleSaveOutsourcingDetails = async () => {
    const saleId = currentSaleId;
    const sueldoLiquido = document.getElementById('sueldoLiquidoInput').value;
    const candidateName = document.getElementById('candidateNameInput').value;
    const contractType = document.getElementById('contractTypeSelect').value;
    const workMode = document.getElementById('workModeSelect').value;
    const colacion = document.getElementById('colacionInput').value || '0';
    const movilizacion = document.getElementById('movilizacionInput').value || '0';
    const asignacionTeletrabajo = document.getElementById('asignacionTeletrabajoInput').value || '0';
    const emailLicense = document.getElementById('emailLicenseSelect').value;
    const costoVentaUf = document.getElementById('costoVentaUfInput').value || '0';  // Nuevo campo
  
    setShowOutsourcingModal(false);
    setShowLoadingModal(true);  // Mostrar modal de carga
  
    if (sueldoLiquido && candidateName && selectedContact) {
        try {
            const response = await fetch('/update_outsourcing_details', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `sale_id=${encodeURIComponent(saleId)}&candidate_name=${encodeURIComponent(candidateName)}&sueldo_liquido=${encodeURIComponent(sueldoLiquido)}&contract_type=${encodeURIComponent(contractType)}&work_mode=${encodeURIComponent(workMode)}&colacion=${encodeURIComponent(colacion)}&movilizacion=${encodeURIComponent(movilizacion)}&asignacion_teletrabajo=${encodeURIComponent(asignacionTeletrabajo)}&email_license=${encodeURIComponent(emailLicense)}&contact_id=${encodeURIComponent(selectedContact)}&costo_venta_uf=${encodeURIComponent(costoVentaUf)}`  // Agregar nuevo campo
            });
            const data = await response.json();
            toastr.success(data.message);
            setShowServiceOrderModal(true);
        } catch (error) {
            console.error('Error updating sale candidate:', error);
            toastr.error('Error updating sale candidate.');
        } finally {
            setShowLoadingModal(false);  // Ocultar modal de carga
        }
    } else {
        toastr.warning('Please fill in all fields.');
        setShowLoadingModal(false);  // Ocultar modal de carga
    }
};
  

  const fetchContacts = async (saleId) => {
    try {
      const response = await fetch(`/get_contacts/${saleId}`);
      const data = await response.json();
      setContacts(data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  useEffect(() => {
    if (showReclutamientoModal && currentSaleId) {
      fetchContacts(currentSaleId);
    }
  }, [showReclutamientoModal, currentSaleId]);


  const handleSaveReclutamientoDetails = async () => {
  const saleId = currentSaleId;
  const candidateName = document.getElementById('reclutamientoCandidateNameInput').value;
  const sueldoLiquido = document.getElementById('sueldoLiquidoInput').value;
  const costoVenta = document.getElementById('costoVentaInput').value;
  const ventaManual = showManualSaleInput ? document.getElementById('manualSaleInput').value : null;

  setShowReclutamientoModal(false);
  setShowLoadingModal(true);  // Mostrar modal de carga

  if (candidateName && sueldoLiquido && costoVenta && selectedContact) {
      try {
          const response = await fetch('/update_reclutamiento_details', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: `candidate_name=${encodeURIComponent(candidateName)}&sale_id=${encodeURIComponent(saleId)}&sueldo_liquido=${encodeURIComponent(sueldoLiquido)}&contact_id=${encodeURIComponent(selectedContact)}&costo_venta=${encodeURIComponent(costoVenta)}${ventaManual ? `&venta_manual=${encodeURIComponent(ventaManual)}` : ''}`  // Incluye venta manual solo si está presente
          });
          const data = await response.json();
          toastr.success(data.message);
          setShowServiceOrderModal(true);
      } catch (error) {
          console.error('Error updating reclutamiento details:', error);
          toastr.error('Error updating reclutamiento details.');
      } finally {
          setShowLoadingModal(false);  // Ocultar modal de carga
      }
  } else {
      toastr.warning('Please fill in all fields.');
      setShowLoadingModal(false);  // Ocultar modal de carga
  }
};


useEffect(() => {
  if (showReclutamientoModal) {
    setShowManualSaleInput(false); // Reinicia el estado cada vez que se abre el modal
  }
}, [showReclutamientoModal]);

useEffect(() => {
  if (!showManualSaleInput) {
    const manualSaleInput = document.getElementById('manualSaleInput');
    if (manualSaleInput) {
      manualSaleInput.value = ''; // Limpia el valor del input
    }
  }
}, [showManualSaleInput]);
  

const generateAndAttachServiceOrder = async (saleId, serviceName) => {
  try {
    setShowLoadingModal(true);
    startProgressBar();
    const response = await fetch(`/generate_service_order?service_name=${serviceName}&sale_id=${saleId}`);
    const osBlob = await response.blob();
    const fileName = `OS_${serviceName}_${saleId}.pdf`;
    const filePath = URL.createObjectURL(osBlob);
    
    setServiceOrderFilePath(filePath); // Almacenar la ruta del archivo OS
    
    const formData = new FormData();
    formData.append("saleFile", osBlob, fileName);
    formData.append("sale_id", saleId);
    
    const uploadResponse = await fetch("/upload_sale_file", {
      method: "POST",
      body: formData
    });
    const uploadData = await uploadResponse.json();
    if (uploadData.message === "File uploaded successfully") {
      toastr.success('Orden de servicio generada y adjuntada exitosamente', 'Orden de Servicio Generada');
      addAutomaticComment("Se ha adjuntado la OS.");
      loadSaleFiles(saleId);
      setLoadingProgress(100);
      setTimeout(() => setShowLoadingModal(false), 1000);
      
      setCurrentSaleId(saleId);
      setCurrentServiceName(serviceName);
      setShowSendServiceOrderModal(true);
    }
  } catch (error) {
    toastr.error('Error generating and attaching service order', 'Error');
    console.error('Error generating and attaching service order:', error);
    setShowLoadingModal(false);
  }
};


  const startProgressBar = () => {
    let progress = 0;
    const interval = setInterval(() => {
      progress += Math.random() * 10;
      progress = Math.min(progress, 99);
      setLoadingProgress(progress);
      if (progress >= 99) {
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleConfirmServiceOrder = () => {
    generateAndAttachServiceOrder(currentSaleId, serviceName);
    setShowServiceOrderModal(false);
  };

  const sendServiceOrderByEmail = async (saleId, serviceName) => {
    try {
      setShowSendServiceOrderModal(false);
      setShowLoadingModal(true);
      startProgressBar();
  
      const response = await fetch('/send_service_order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `sale_id=${encodeURIComponent(saleId)}&service_name=${encodeURIComponent(serviceName)}`
      });
      const data = await response.json();
      toastr.success(data.message, 'Orden de Servicio Enviada');
    } catch (error) {
      toastr.error('Error sending service order', 'Error');
      console.error('Error sending email:', error);
    } finally {
      setShowLoadingModal(false);
    }
  };

  const handleCloseSale = async () => {
    try {
      const response = await fetch('/update_sale_stage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `sale_id=${encodeURIComponent(currentSaleId)}&new_stage=Cierre&status=${encodeURIComponent(closeSaleStatus)}`
      });
      const data = await response.json();
      if (data.message === 'Sale stage updated successfully') {
        toastr.success('La venta ha sido cerrada con éxito');
      } else {
        toastr.error('Hubo un error al cerrar la venta');
      }
      setShowCloseSaleModal(false);
      resetCloseSaleFields();
      loadKanbanBoard();
    } catch (error) {
      toastr.error('Error cerrando la venta: ' + error.message);
      console.error('Error closing sale:', error);
    }
  };

  const handleCancelCloseSale = () => {
    const updatedSales = sales.map((sale) =>
      sale.sale_id === currentSaleId ? { ...sale, stage: originalStage } : sale
    );
    setSales(updatedSales);

    setShowCloseSaleModal(false);
    resetCloseSaleFields();
  };

  const resetNewSaleFields = () => {
    setNewSale({
      client: '',
      solicitudes: [{ service: 'Reclutamiento', solicitud: '', observaciones: '' }]
    });
  };

  const resetCloseSaleFields = () => {
    setCloseSaleStatus('');
  };

  const resetOutsourcingFields = () => {
    document.getElementById('sueldoBaseInput').value = '';
    document.getElementById('candidateNameInput').value = '';
    document.getElementById('contractTypeSelect').value = '';
    document.getElementById('workModeSelect').value = '';
    document.getElementById('colacionInput').value = '0';
    document.getElementById('movilizacionInput').value = '0';
    document.getElementById('asignacionTeletrabajoInput').value = '0';
    document.getElementById('emailLicenseSelect').value = '';
  };

  const resetReclutamientoFields = () => {
    document.getElementById('reclutamientoCandidateNameInput').value = '';
    document.getElementById('rentaBrutaInput').value = '';
  };

  const handleDragEnd = async (result) => {
    const { source, destination, draggableId } = result;

    const trashBin = document.getElementById('trashBin');
    trashBin.classList.remove('dragging-over');

    if (!destination) return;

    if (destination.droppableId === 'trashBin') {
      handleDeleteSaleRequest(draggableId);
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const sale = sales.find(s => s.sale_id === draggableId);
      const sourceStage = source.droppableId;
      const targetStage = destination.droppableId;

      if (!isAdmin) {
        if (
          (targetStage === 'Reunión inicial' && sourceStage !== 'Reunión inicial') ||
          (targetStage === 'Requerimiento' && sourceStage !== 'Reunión inicial') ||
          (targetStage === 'Negociación' && sourceStage !== 'Requerimiento') ||
          (targetStage === 'Cierre' && sourceStage !== 'Negociación') ||
          (sourceStage === 'Cierre' && targetStage === 'Seguimiento')
        ) {
          return;
        }
      }

      setOriginalStage(sale.stage);

      if (destination.droppableId === 'Cierre') {
        setCurrentSaleId(draggableId);
        setShowCloseSaleModal(true);
      } else {
        const updatedSales = Array.from(sales);
        const [movedSale] = updatedSales.splice(source.index, 1);
        movedSale.stage = destination.droppableId;
        updatedSales.splice(destination.index, 0, movedSale);

        setSales(updatedSales);
        handleStageChange(draggableId, destination.droppableId);
      }
    }
  };

  const handleDeleteSaleRequest = (saleId) => {
    setSaleToDelete(saleId);
    setShowDeleteModal(true);
  };

  const handleConfirmDeleteSale = async () => {
    try {
      const response = await fetch('/delete_sale', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `sale_id=${encodeURIComponent(saleToDelete)}`
      });
      const data = await response.json();
      console.log(data.message);
      toastr.success('Venta eliminada con éxito');
      setShowDeleteModal(false);
      setSaleToDelete(null);
      loadKanbanBoard();
    } catch (error) {
      console.error('Error deleting sale:', error);
    }
  };


  const fetchUsers = async () => {
    try {
      const response = await fetch('/get_all_users');
      const data = await response.json();
      if (data.success) {
        setUsers(data.users);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };


  const handleAssignSaleResponsable = (saleId, responsableEmail) => {
    fetch('/assign_sale_responsable', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        sale_id: saleId,
        responsable_email: responsableEmail
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          toastr.success(data.message);
          setSales(sales.map(sale =>
            sale.sale_id === saleId ? { ...sale, responsable: responsableEmail } : sale
          ));
          // Emitir un evento de socket
          socket.emit('update_sale_responsable', { sale_id: saleId, responsable: responsableEmail });
        } else {
          toastr.error(data.message);
        }
      })
      .catch(error => toastr.error('Error inesperado al asignar el responsable.'));
  };


  const loadMoreSales = useCallback(() => {
    if (loadingMore || !hasMore) return;
    
    setLoadingMore(true);
    setTimeout(() => {
        const newVisibleSales = sales.slice(visibleSales.length, visibleSales.length + itemsPerPage);
        setVisibleSales(prevVisibleSales => [...prevVisibleSales, ...newVisibleSales]);
        setHasMore(newVisibleSales.length === itemsPerPage);
        setLoadingMore(false);
    }, 500); // Simula un retardo en la carga
}, [loadingMore, hasMore, sales, visibleSales.length]);


useEffect(() => {
  setVisibleSales(sales.slice(0, itemsPerPage));
  setHasMore(sales.length > itemsPerPage);
}, [sales]);


useEffect(() => {
  const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loadingMore) return;
      loadMoreSales();
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, [loadMoreSales, loadingMore]);



  return (
    <div className="kanban-sales-section col-md-12">
      <h3 className="kanban-sales-title"></h3>

      <form id="filterForm" className="mb-4" onSubmit={handleFilter}>
        <div className="form-row">
          <div className="col">
            <select name="stage" className="form-control">
              <option value="">--Seleccionar Etapa--</option>
              {stages.map(stage => <option value={stage} key={stage}>{stage}</option>)}
            </select>
          </div>
          <div className="col">
            <input type="text" name="responsible" className="form-control" placeholder="Responsable" />
          </div>
          <div className="col">
            <input type="date" name="date" className="form-control" />
          </div>
          <div className="col">
            <button type="submit" className="btn btn-primary">Filtrar</button>
          </div>
        </div>
      </form>

      <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate}>
        <div className="container mt-5">
          <div className="row d-flex">
            <div className="col-md-11 flex-grow-1 kanban-container">
              <div className="kanban-row">
                {stages.map(stage => (
                  <div key={stage} className="kanban-column-container">
                    <div className="kanban-column-header">
                      <h4>{stage}</h4>
                    </div>
                    <Droppable droppableId={stage}>
                      {(provided) => (
                        <div
                          className="kanban-column"
                          id={stage.toLowerCase().replace(/\s+/g, '-')}
                          data-stage={stage}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <div className="kanban-cards-container">
                          {visibleSales.filter(sale => sale.stage === stage).map((sale, index) => (
    <KanbanCard
        key={sale.sale_id}
        sale={sale}
        index={index}
        handleCardDoubleClick={handleCardDoubleClick}
    />
))}

                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                ))}
                <Droppable droppableId="trashBin">
                  {(provided) => (
                    <div className="kanban-trash-bin" id="trashBin" ref={provided.innerRef} {...provided.droppableProps}>
                      <i className="fas fa-trash-alt fa-3x"></i>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className="kanban-fixed-button-container">
                <button type="button" className="btn btn-primary mb-2 kanban-fixed-button" onClick={() => setShowAddSaleModal(true)}>
                  Agregar venta
                </button>
              </div>
            </div>
          </div>
        </div>
      </DragDropContext>

      <Modal 
  show={showAddSaleModal} 
  onHide={() => { setShowAddSaleModal(false); resetNewSaleFields(); }} 
  backdrop="static" 
  keyboard={false}
  className="add-sale-modal" // Añadir esta línea
>
  <Modal.Header closeButton>
    <Modal.Title>Agregar Venta</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="form-group">
      <label htmlFor="clientSelect">Selecciona un cliente:</label>
      <select 
        className="kanban-form-control form-control" 
        id="clientSelect" 
        name="client" 
        value={newSale.client} 
        onChange={(e) => setNewSale({ ...newSale, client: e.target.value })}
      >
        <option value="">--Seleccionar Cliente--</option>
        {clients.map(client => (
          <option value={client.id} key={client.id}>{client.company_name}</option>
        ))}
      </select>
    </div>

    <div className="form-group">
      <label htmlFor="solicitudesContainer">Solicitudes:</label>
      <div id="solicitudesContainer">
        {newSale.solicitudes.map((solicitud, index) => (
          <div className="solicitudItem" key={index}>
            <div className="solicitudHeader">
              <label htmlFor={`serviceSelect-${index}`}>Seleccione un servicio:</label>
              <button type="button" className="closeSolicitud" onClick={() => handleRemoveSolicitud(index)}>×</button>
            </div>
            <div className="form-group">
              <select 
                className="serviceSelect form-control" 
                name="serviceSelect" 
                value={solicitud.service} 
                onChange={(e) => {
                  const newSolicitudes = [...newSale.solicitudes];
                  newSolicitudes[index].service = e.target.value;
                  setNewSale({ ...newSale, solicitudes: newSolicitudes });
                }}
              >
                <option value="Reclutamiento">Reclutamiento</option>
                <option value="Outsourcing">Outsourcing</option>
                <option value="Ciberseguridad">Ciberseguridad</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor={`solicitud-${index}`}>Solicitud:</label>
              <input 
                className="solicitudInput form-control" 
                name="solicitud" 
                type="text" 
                value={solicitud.solicitud} 
                onChange={(e) => {
                  const newSolicitudes = [...newSale.solicitudes];
                  newSolicitudes[index].solicitud = e.target.value;
                  setNewSale({ ...newSale, solicitudes: newSolicitudes });
                }} 
              />
            </div>
            <div className="form-group">
              <label htmlFor={`observaciones-${index}`}>Observaciones:</label>
              <textarea 
                className="observacionesInput form-control" 
                name="observaciones" 
                value={solicitud.observaciones} 
                onChange={(e) => {
                  const newSolicitudes = [...newSale.solicitudes];
                  newSolicitudes[index].observaciones = e.target.value;
                  setNewSale({ ...newSale, solicitudes: newSolicitudes });
                }} 
              />
            </div>
          </div>
        ))}
      </div>
      <button type="button" className="btn btn-link" onClick={handleAddSolicitud}>Agregar otra solicitud</button>
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => { setShowAddSaleModal(false); resetNewSaleFields(); }}>Cerrar</Button>
    <Button variant="primary" onClick={handleAddSale}>Agregar Venta</Button>
  </Modal.Footer>
</Modal>


<Modal className="sale-details-modal" show={showSaleDetailsModal} onHide={handleModalClose} dialogClassName="custom-modal">
            <Modal.Header closeButton>
                <Modal.Title>Detalles de Venta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {saleDetails && (
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="kanban-details-section">
                                    <h5 className="kanban-section-header">Información de la Venta</h5>
                                    <div className="kanban-details-list">
                                        {[
                                            { label: 'ID de venta', value: saleDetails.sale_id },
                                            { label: 'Cliente', value: saleDetails.client_name },
                                            { label: 'Servicio', value: saleDetails.service },
                                            { label: 'Solicitud', value: saleDetails.solicitud },
                                            { label: 'Observaciones', value: saleDetails.observaciones },
                                            { label: 'Responsable de la venta', value: saleDetails.responsable },
                                            { label: 'Etapa', value: saleDetails.stage },
                                            { label: 'Fecha de creación', value: new Date(saleDetails.created_at).toLocaleString() },
                                        ].map((detail, index) => (
                                            <div className="kanban-details-row" key={index}>
                                                <label>{detail.label}:</label>
                                                <span>{detail.value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="kanban-details-section">
                                    <h5 className="kanban-section-header">Comentarios</h5>
                                    <div className="kanban-comments-container">
                                        {comments.map(comment => (
                                            <div className="kanban-comment" key={comment.comment_id} data-comment-id={comment.comment_id}>
                                                <p className="comment-text">{comment.text}</p>
                                                <p className="event-date">
                                                    <strong>{comment.author}</strong> ({new Date(comment.timestamp).toLocaleString()})
                                                </p>
                                                <div className="kanban-comment-buttons">
                                                    {!comment.is_automatic && (
                                                        <button className="btn btn-sm btn-outline-secondary" onClick={() => showEditCommentInput(comment.comment_id)}>Editar</button>
                                                    )}
                                                    <button className="btn btn-sm btn-outline-danger" style={{ display: 'none' }} onClick={() => deleteComment(comment.comment_id)}>Eliminar</button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="kanban-comment-input">
                                        <textarea id="newComment" placeholder="Añadir un comentario..." className="mb-2" value={newComment} onChange={(e) => setNewComment(e.target.value)}></textarea>
                                        <button className="btn btn-outline-primary" onClick={handleCommentSubmit}>Agregar comentario</button>
                                    </div>
                                </div>
                                <div className="kanban-details-section">
                                    <h5 className="kanban-section-header">Archivos Adjuntos</h5>
                                    <input type="file" id="saleFile" name="saleFile" className="mb-2" onChange={(e) => setSelectedFile(e.target.files[0])} />
                                    <button className="btn btn-outline-primary mb-2" onClick={handleFileUpload}>Subir</button>
                                    <div className="mt-2">
                                        {files.map(file => (
                                            <div className="kanban-file-container" key={file.file_id}>
                                                <a href={file.file_path} target="_blank" rel="noopener noreferrer">{file.file_name}</a>
                                                <span className="file-date">({file.upload_date})</span>
                                                <a href={file.file_path} download={file.file_name} className="download-icon"><i className="fas fa-download"></i></a>
                                                <span className="delete-icon" onClick={() => deleteSaleFile(file.file_id)}>×</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="kanban-details-section">
                                    <h5 className="kanban-section-header">Asignar Responsable</h5>
                                    {isAdmin && (
                                        <Form>
                                            <Form.Group controlId="formResponsableEmail">
                                                <Form.Label>Seleccionar Responsable</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    onChange={(e) => handleAssignSaleResponsable(saleDetails.sale_id, e.target.value)}
                                                    required
                                                >
                                                    <option value="">Seleccione un responsable</option>
                                                    {users.map(user => (
                                                        <option key={user.id} value={user.format}>
                                                            {user.format}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>

<Modal show={showProposalModal} onHide={() => setShowProposalModal(false)} backdrop="static" className="proposal-modal">
        <Modal.Header closeButton>
          <Modal.Title>Generar Propuesta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="contactSelect" className="form-label">Seleccionar Contacto</label>
            <select className="kanban-form-control form-control" id="contactSelect" value={selectedContact} onChange={(e) => setSelectedContact(e.target.value)}>
              <option value="">Selecciona un contacto</option>
              {contacts.map(contact => (
                <option key={contact.id} value={contact.id}>{contact.contact_name}</option>
              ))}
            </select>
          </div>
          ¿Desea generar la propuesta para el cliente?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowProposalModal(false)}>Cancelar</Button>
          <Button variant="primary" onClick={generateProposal}>Generar Propuesta</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSendProposalModal} onHide={() => setShowSendProposalModal(false)} backdrop="static" className="send-proposal-modal">
  <Modal.Header closeButton>
    <Modal.Title>Enviar Propuesta</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>¿Desea enviar la propuesta al cliente?</p>
    {proposalFilePath && (
      <div>
        <iframe src={proposalFilePath} width="100%" height="400px"></iframe>
      </div>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowSendProposalModal(false)}>Cancelar</Button>
    <Button variant="primary" onClick={sendProposal}>Enviar Propuesta</Button>
  </Modal.Footer>
</Modal>


<Modal show={showOutsourcingModal} onHide={() => setShowOutsourcingModal(false)} backdrop="static" className="outsourcing-modal">
  <Modal.Header closeButton>
    <Modal.Title>Ingresar Detalles de la Venta</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="mb-3">
      <label htmlFor="sueldoLiquidoInput" className="form-label">Sueldo Líquido</label>
      <input type="number" className="kanban-form-control form-control" id="sueldoLiquidoInput" placeholder="Ingresa el monto" />
    </div>
    <div className="mb-3">
      <label htmlFor="candidateNameInput" className="form-label">Nombre del Candidato</label>
      <input type="text" className="kanban-form-control form-control" id="candidateNameInput" placeholder="Ingresa el nombre del candidato" />
    </div>
    <div className="mb-3">
      <label htmlFor="contractTypeSelect" className="form-label">Tipo de contrato</label>
      <select className="kanban-form-control form-control" id="contractTypeSelect">
        <option value="Plazo fijo">Plazo fijo</option>
        <option value="Indefinido">Indefinido</option>
      </select>
    </div>
    <div className="mb-3">
      <label htmlFor="workModeSelect" className="form-label">Modalidad de trabajo</label>
      <select className="kanban-form-control form-control" id="workModeSelect" onChange={handleWorkModeChange}>
        <option value="Presencial">Presencial</option>
        <option value="Teletrabajo">Teletrabajo</option>
        <option value="Híbrido">Híbrido</option>
      </select>
    </div>
    <div className="mb-3" id="colacionField">
      <label htmlFor="colacionInput" className="form-label">Colación</label>
      <input type="number" className="kanban-form-control form-control" id="colacionInput" placeholder="Ingresa el monto de colación" defaultValue="0" />
    </div>
    <div className="mb-3" id="movilizacionField">
      <label htmlFor="movilizacionInput" className="form-label">Movilización</label>
      <input type="number" className="kanban-form-control form-control" id="movilizacionInput" placeholder="Ingresa el monto de movilización" defaultValue="0" />
    </div>
    <div className="mb-3" id="asignacionTeletrabajoField" style={{ display: 'none' }}>
      <label htmlFor="asignacionTeletrabajoInput" className="form-label">Asignación Teletrabajo</label>
      <input type="number" className="kanban-form-control form-control" id="asignacionTeletrabajoInput" placeholder="Ingresa el monto de asignación teletrabajo" defaultValue="0" />
    </div>
    <div className="mb-3">
      <label htmlFor="emailLicenseSelect" className="form-label">Licencia correo electrónico</label>
      <select className="kanban-form-control form-control" id="emailLicenseSelect">
        <option value="No">No</option>
        <option value="Si">Si</option>
      </select>
    </div>
    <div className="mb-3">
      <label htmlFor="contactSelect" className="form-label">Seleccionar Contacto</label>
      <select className="kanban-form-control form-control" id="contactSelect" value={selectedContact} onChange={(e) => setSelectedContact(e.target.value)}>
        <option value="">Selecciona un contacto</option>
        {contacts.map(contact => (
          <option key={contact.id} value={contact.id}>{contact.contact_name}</option>
        ))}
      </select>
    </div>
    <div className="mb-3">
      <label htmlFor="costoVentaUfInput" className="form-label">Costo Venta UF</label>
      <input type="number" className="kanban-form-control form-control" id="costoVentaUfInput" placeholder="Ingresa el costo de venta en UF" />
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowOutsourcingModal(false)}>Cancelar</Button>
    <Button variant="primary" onClick={handleSaveOutsourcingDetails}>Guardar</Button>
  </Modal.Footer>
</Modal>


      <Modal show={showReclutamientoModal} onHide={() => setShowReclutamientoModal(false)} backdrop="static" className="reclutamiento-modal">
  <Modal.Header closeButton>
    <Modal.Title>Detalles del Reclutamiento</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="mb-3">
      <label htmlFor="reclutamientoCandidateNameInput" className="form-label">Nombre del Candidato</label>
      <input type="text" className="kanban-form-control form-control" id="reclutamientoCandidateNameInput" placeholder="Ingresa el nombre del candidato" />
    </div>
    <div className="mb-3">
      <label htmlFor="sueldoLiquidoInput" className="form-label">Sueldo Líquido Mensual</label>
      <input type="number" className="kanban-form-control form-control" id="sueldoLiquidoInput" placeholder="Ingresa el sueldo líquido mensual" />
    </div>
    <div className="mb-3">
      <label htmlFor="costoVentaInput" className="form-label">Costo de la Venta (UF)</label>
      <input type="number" className="kanban-form-control form-control" id="costoVentaInput" placeholder="Ingresa el costo de la venta en UF" />
    </div>
    <div className="mb-3">
      <label htmlFor="contactSelect" className="form-label">Seleccionar Contacto</label>
      <select className="kanban-form-control form-control" id="contactSelect" value={selectedContact} onChange={(e) => setSelectedContact(e.target.value)}>
        <option value="">Selecciona un contacto</option>
        {contacts.map(contact => (
          <option key={contact.id} value={contact.id}>{contact.contact_name}</option>
        ))}
      </select>
    </div>
    {isAdmin && (
  <>
    <div className="mb-3">
      <label htmlFor="toggleManualSale" className="form-label">¿Ingresar Venta Manual?</label>
      <select 
        className="kanban-form-control form-control" 
        id="toggleManualSale" 
        onChange={(e) => setShowManualSaleInput(e.target.value === "Si")}>
        <option value="No">No</option>
        <option value="Si">Sí</option>
      </select>
    </div>
    {showManualSaleInput && (
      <div className="mb-3">
        <label htmlFor="manualSaleInput" className="form-label">Ingresar Venta Manual</label>
        <input type="number" className="kanban-form-control form-control" id="manualSaleInput" placeholder="Ingresa el monto manual" />
      </div>
    )}
  </>
)}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowReclutamientoModal(false)}>Cancelar</Button>
    <Button variant="primary" onClick={handleSaveReclutamientoDetails}>Guardar</Button>
  </Modal.Footer>
</Modal>


      <Modal show={showLoadingModal} onHide={() => setShowLoadingModal(false)} backdrop="static" keyboard={false} className="loading-modal" centered>
  <Modal.Body className="text-center">
    <div className="spinner-border text-primary mb-3" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
    <p>Cargando, por favor espera...</p>
    <div className="progress">
      <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={loadingProgress} aria-valuemin="0" aria-valuemax="100" style={{ width: `${loadingProgress}%` }}></div>
    </div>
  </Modal.Body>
</Modal>

<Modal show={showServiceOrderModal} onHide={() => setShowServiceOrderModal(false)} backdrop="static" className="service-order-modal">
        <Modal.Header closeButton>
          <Modal.Title>Generar Orden de Servicio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Deseas generar la orden de servicio para esta venta?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowServiceOrderModal(false)}>Cancelar</Button>
          <Button variant="primary" onClick={handleConfirmServiceOrder}>Confirmar</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSendServiceOrderModal} onHide={() => setShowSendServiceOrderModal(false)} backdrop="static" className="send-service-order-modal">
  <Modal.Header closeButton>
    <Modal.Title>Enviar Orden de Servicio</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>¿Desea enviar la orden de servicio al cliente?</p>
    {serviceOrderFilePath && (
      <div>
        <iframe src={serviceOrderFilePath} width="100%" height="400px"></iframe>
      </div>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowSendServiceOrderModal(false)}>Cancelar</Button>
    <Button variant="primary" onClick={() => {
      sendServiceOrderByEmail(currentSaleId, currentServiceName);
    }}>Enviar Orden</Button>
  </Modal.Footer>
</Modal>


      <Modal show={showCloseSaleModal} onHide={() => { setShowCloseSaleModal(false); resetCloseSaleFields(); }}backdrop="static" className="close-sale-modal">
        <Modal.Header>
          <Modal.Title>Cerrar Venta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Estás seguro de que deseas cerrar esta venta?</p>
          <Form.Group controlId="closeSaleStatus">
            <Form.Label>Estado de la venta</Form.Label>
            <Form.Control as="select" value={closeSaleStatus} onChange={(e) => setCloseSaleStatus(e.target.value)}>
              <option value="">Seleccionar</option>
              <option value="ganada">Ganada</option>
              <option value="perdida">Perdida</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelCloseSale}>Cancelar</Button>
          <Button variant="success" onClick={handleCloseSale}>Cerrar Venta</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} backdrop="static" className="delete-modal">
  <Modal.Header closeButton>
    <Modal.Title>Confirmar Eliminación</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    ¿Estás seguro de que deseas eliminar esta venta?
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancelar</Button>
    <Button variant="danger" onClick={handleConfirmDeleteSale}>Eliminar</Button>
  </Modal.Footer>
</Modal>

    </div>
  );
};

export default KanbanBoard;
