import React, { useState, useEffect } from 'react';
import './Users.css';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import './Sidebar.css';

const Users = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/get_session_data')
            .then(response => response.json())
            .then(data => {
                if (!data.username) {
                    navigate('/');
                } else {
                    fetch('/manage_users')
                        .then(response => response.json())
                        .then(data => setUsers(data));
                }
            })
            .catch(error => {
                console.error('Error fetching session data:', error);
                navigate('/');
            });
    }, [navigate]);

    const handleDelete = (userId) => {
        fetch(`/delete_user/${userId}`, { method: 'POST' })
            .then(() => {
                setUsers(users.filter(user => user.id !== userId));
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        fetch('/manage_users', {
            method: 'POST',
            body: formData
        }).then(() => {
            window.location.reload();
        });
    };

    const toggleSidebar = () => {
        document.getElementById('sidebar').classList.toggle('collapsed');
        document.getElementById('toggle-btn').classList.toggle('collapsed');
    };

    return (
        <div className="users-container">
            <Sidebar toggleSidebar={toggleSidebar} />
            <h2>Gestionar Usuarios</h2>
            <table className="users-table users-table-striped">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Rol</th>
                        <th>Cargo</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            <td>{user.position}</td>
                            <td>
                                <button 
                                    onClick={() => handleDelete(user.id)} 
                                    className="users-button">
                                    Eliminar
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <h3>Agregar o Modificar Usuario</h3>
            <form onSubmit={handleSubmit} className="users-form">
                <label htmlFor="email">Email:</label>
                <input type="email" name="email" required className="users-input" />

                <label htmlFor="role">Rol:</label>
                <select name="role" className="users-select">
                    <option value="user">Usuario</option>
                    <option value="admin">Administrador</option>
                </select>

                <label htmlFor="position">Cargo:</label>
                <input type="text" name="position" className="users-input" />

                <button type="submit" className="users-button">Guardar</button>
            </form>
        </div>
    );
};

export default Users;
