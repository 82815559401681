import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './NavBar.css'; // Importa los estilos personalizados

function NavBar({ session, changeUser }) {
    const currentDomain = session.email.includes('@') ? session.email.split('@')[1] : '';

    return (
        <nav className="navbar navbar-expand-lg navbar-dark custom-navbar">
            <div className="container-fluid"> {/* Usa container-fluid para un mejor ajuste */}
                <a className="navbar-brand" href="#">
                    <img src={`${process.env.PUBLIC_URL}/iconoeva.ico`} width="30" height="30" className="d-inline-block align-top" alt="" />
                    EVA
                </a>
                <span className="navbar-text">Conectado como: {session.username} - {currentDomain}</span>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ml-auto"> {/* Agregamos ml-auto para empujar los elementos hacia la derecha */}
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown">
                                Cambiar Usuario
                            </a>
                            <div className="dropdown-menu" aria-labelledby="userDropdown">
                                {session.all_users.map(user => {
                                    const domain = user.email.includes('@') ? user.email.split('@')[1] : '';
                                    return (
                                        <a
                                            key={user.email}
                                            className="dropdown-item"
                                            href="#"
                                            onClick={() => changeUser(user.email)}
                                        >
                                            {user.username} - {domain}
                                        </a>
                                    );
                                })}
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="btn btn-light" href="/login">Agregar cuenta</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/logout">
                                <i className="fas fa-sign-out-alt"></i> Cerrar sesión
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
