import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import toastr from 'toastr';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'toastr/build/toastr.min.css';
import io from 'socket.io-client';
import './ClientsTable.css';
import './DarkThemeModals.css';
import { format } from 'date-fns';
import { useClients } from '../ClientsContext';

const socket = io();

const ClientsTable = () => {
    const { clients, setClients, deleteClientAndSales, sales, setShowSaleDetailsModal, setSaleDetails } = useClients();
    const [showAddClientModal, setShowAddClientModal] = useState(false);
    const [showClientHistoryModal, setShowClientHistoryModal] = useState(false);
    const [showSendEmailModal, setShowSendEmailModal] = useState(false);
    const [showWhatsappModal, setShowWhatsappModal] = useState(false);
    const [showScheduleMeetingModal, setShowScheduleMeetingModal] = useState(false);
    const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(false);
    const [showSelectWhatsappTemplateModal, setShowSelectWhatsappTemplateModal] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [selectedClient, setSelectedClient] = useState({});
    const [clientToDelete, setClientToDelete] = useState(null);
    const [eventHistory, setEventHistory] = useState([]);
    const [userHistory, setUserHistory] = useState([]);
    const [salesHistory, setSalesHistory] = useState([]);
    const [clientInfo, setClientInfo] = useState({});
    const [editClientInfo, setEditClientInfo] = useState({});
    const [contacts, setContacts] = useState({}); // Nuevo estado para almacenar los contactos de los clientes
    const [selectedContactId, setSelectedContactId] = useState('');
    const [selectedMeetingContactId, setSelectedMeetingContactId] = useState('');
    const [selectedWhatsappContactId, setSelectedWhatsappContactId] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]); // Para almacenar los archivos subidos
    const [uploadedFilePath, setUploadedFilePath] = useState('');
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [showEditContactModal, setShowEditContactModal] = useState(false);
    const [isEditingClientInfo, setIsEditingClientInfo] = useState(false);
    const [contactsInfo, setContactsInfo] = useState([]);
    const [showAddContactModal, setShowAddContactModal] = useState(false);
    const [showAssignResponsableModal, setShowAssignResponsableModal] = useState(false);
    const [responsableEmail, setResponsableEmail] = useState('');
    const [users, setUsers] = useState([]); // Nuevo estado para almacenar los usuarios
    const [searchQuery, setSearchQuery] = useState('');
    const [newContact, setNewContact] = useState({ contact_name: '', number: '', email: '', position: '' });
    const [currentContact, setCurrentContact] = useState({
        contact_name: '',
        number: '',
        email: '',
        position: '',
        id: ''  // Asegúrate de incluir el id aquí
    });

    const [formData, setFormData] = useState({
        company_name: '',
        description: '',
        contacts: [{ contact_name: '', number: '', email: '', position: '' }]
    });

    const [eventDescription, setEventDescription] = useState('');
    const [editEventDescription, setEditEventDescription] = useState({});
    const [whatsappMessage, setWhatsappMessage] = useState('');
    const [emailFormData, setEmailFormData] = useState({
        cc: '',
        subject: '',
        body: ''
    });
    const [scheduleMeetingData, setScheduleMeetingData] = useState({
        date: '',
        time: '',
        title: '',
        invitees: []
    });
    const [whatsappTemplates, setWhatsappTemplates] = useState([]);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [activeTab, setActiveTab] = useState('events');

    toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: true,
        progressBar: true,
        positionClass: 'toast-bottom-left',
        preventDuplicates: false,
        onclick: null,
        showDuration: '300',
        hideDuration: '1000',
        timeOut: '5000',
        extendedTimeOut: '1000',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        toastClass: 'toast-custom'
    };

    useEffect(() => {
        fetch(`/get_all_clients_data?query=${searchQuery}`)
            .then(response => response.json())
            .then(data => {
                const clientsList = Object.keys(data).map(clientId => ({
                    id: clientId,
                    company_name: data[clientId].company_name || 'N/A',
                    description: data[clientId].description || 'N/A',
                    responsable: data[clientId].responsable || 'N/A',
                    color: data[clientId].color,
                    has_taken: data[clientId].has_taken,
                    status: data[clientId].status || 'N/A'
                }));
                setClients(clientsList);

                // Obtener contactos para cada cliente
                clientsList.forEach(client => {
                    fetchClientContacts(client.id);
                });
            })
            .catch(error => console.error('Error fetching clients:', error));

        socket.on('update_clients', (data) => {
            if (data.action === 'add') {
                addClientRow(data.client);
            } else if (data.action === 'delete') {
                deleteClientAndSales(data.client_id);
            }
        });

        socket.on('update_client_color', (data) => {
            setClients(prevClients =>
                prevClients.map(client =>
                    client.id === data.client_id ? { ...client, color: data.color, responsable: data.responsable } : client
                )
            );
        });

        fetch('/is_admin')
            .then(response => response.json())
            .then(data => {
                setIsAdmin(data.isAdmin);
            });

        return () => {
            socket.off('update_clients');
            socket.off('update_client_color');
        };
    }, [searchQuery]); // Dependencia para que se ejecute cuando searchQuery cambie

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value); // Actualizar el estado de búsqueda
    };

    const fetchClientContacts = async (clientId) => {
        try {
            const response = await fetch(`/get_client_contacts/${clientId}`);
            const data = await response.json();
            setContacts(prevContacts => ({ ...prevContacts, [clientId]: data }));
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    };

    useEffect(() => {
        if (!showAddClientModal) {
            setFormData({
                company_name: '',
                email: '',
                position: '',
                description: '',
                contacts: [{ contact_name: '', number: '', position: '' }]
            });
        }
    }, [showAddClientModal]);

    useEffect(() => {
        if (!showSendEmailModal) {
            // Llamar a la ruta para eliminar los archivos cuando se cierra el modal
            fetch('/delete_uploaded_files', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ files: uploadedFiles.map(file => file.name) })
            }).then(() => {
                setUploadedFiles([]); // Limpiar los archivos subidos en el frontend
            }).catch(error => console.error('Error eliminando archivos:', error));
        }
    }, [showSendEmailModal]);
    

    useEffect(() => {
        if (!showWhatsappModal) {
            setWhatsappMessage('');
        }
    }, [showWhatsappModal]);

    useEffect(() => {
        if (!showScheduleMeetingModal) {
            setScheduleMeetingData({
                date: '',
                time: '',
                title: '',
                invitees: []
            });
        }
    }, [showScheduleMeetingModal]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleContactChange = (index, e) => {
        const { name, value } = e.target;
        const newContacts = formData.contacts.map((contact, i) => (
            i === index ? { ...contact, [name]: value } : contact
        ));
        setFormData({ ...formData, contacts: newContacts });
    };

    const addContact = () => {
        setFormData({
            ...formData,
            contacts: [...formData.contacts, { contact_name: '', number: '', email: '', position: '' }]
        });
    };

    const removeContact = (index) => {
        setFormData({
            ...formData,
            contacts: formData.contacts.filter((_, i) => i !== index)
        });
    };

    const handleAddClient = (e) => {
        e.preventDefault();

        fetch('/add_client', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                company_name: formData.company_name,
                description: formData.description,
                contacts: formData.contacts.map(contact => `${contact.contact_name}:${contact.number}:${contact.email}:${contact.position}`).join(',')
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setShowAddClientModal(false);
            } else if (data.company_exists) {
                if (window.confirm(data.message)) {
                    fetch('/add_client', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: new URLSearchParams({
                            company_name: formData.company_name,
                            description: formData.description,
                            contacts: formData.contacts.map(contact => `${contact.contact_name}:${contact.number}:${contact.email}:${contact.position}`).join(','),
                            confirm_duplicate: true
                        })
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            setShowAddClientModal(false);
                        } else {
                            toastr.error(data.message);
                        }
                    })
                    .catch(error => toastr.error('Error inesperado al agregar el cliente'));
                }
            } else {
                toastr.error(data.message);
            }
        })
        .catch(error => toastr.error('Error inesperado al agregar el cliente'));
    };

    const addClientRow = (client) => {
        const newClient = {
            id: client.id,
            company_name: client.company_name,
            description: client.description,
            responsable: client.responsable,
            color: client.color,
            has_taken: false
        };

        setClients(prevClients => [...prevClients, newClient]);

        // Actualizar los contactos para el nuevo cliente
        setContacts(prevContacts => ({
            ...prevContacts,
            [client.id]: client.contacts
        }));

        toastr.success(`Nuevo cliente agregado: ${client.company_name}`);
    };

    const handleEditClientInfo = (e) => {
        const { name, value } = e.target;
        setEditClientInfo({
            ...editClientInfo,
            [name]: value
        });
    };


    const saveClientInfo = () => {
        const updatedClientInfo = {
            company_name: editClientInfo.company_name || selectedClient.company_name,
            description: editClientInfo.description || selectedClient.description,
            client_id: selectedClient.id
        };
    
        fetch('/update_client_info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedClientInfo)
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                toastr.success(data.message);
                setClients(clients.map(client =>
                    client.id === selectedClient.id ? { ...client, ...updatedClientInfo } : client
                ));
                setEditClientInfo({});
                openClientHistoryModal(selectedClient.id);
                setIsEditingClientInfo(false); // Cambiar el estado a no editable
            } else {
                toastr.error(data.message);
            }
        })
        .catch(error => toastr.error('Error inesperado al actualizar la información del cliente.'));
    };
    

   

    const handleEditContact = (contact) => {
        setCurrentContact({
            contact_name: contact.contact_name,
            number: contact.number,
            email: contact.email,
            position: contact.position,
            id: contact.contact_id  // Asegúrate de obtener y asignar el contact_id
        });
        setShowEditContactModal(true);
    };
    


 
    const saveContactInfo = () => {
        const updatedContactInfo = {
            contact_name: currentContact.contact_name,
            number: currentContact.number,
            email: currentContact.email,
            position: currentContact.position,
            contact_id: currentContact.id  // Asegúrate de incluir el contact_id
        };
    
        fetch('/update_contact_info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedContactInfo)
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                toastr.success(data.message);
                setContactsInfo(contactsInfo.map(contact =>
                    contact.contact_id === currentContact.id ? { ...contact, ...updatedContactInfo } : contact
                ));
                setShowEditContactModal(false);
            } else {
                toastr.error(data.message);
            }
        })
        .catch(error => toastr.error('Error inesperado al actualizar la información del contacto.'));
    };
    







    const requestDeleteClient = (client) => {
        setClientToDelete(client);
        setShowDeleteConfirmModal(true);
    };

    const handleDeleteClient = () => {
        fetch('/delete_client', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ client_id: clientToDelete.id })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                deleteClientAndSales(clientToDelete.id);
                toastr.success('Cliente eliminado');
            } else {
                toastr.error('Error al eliminar el cliente');
            }
            setShowDeleteConfirmModal(false);
            setClientToDelete(null);
        })
        .catch(error => {
            console.error('Error deleting client:', error);
            setShowDeleteConfirmModal(false);
            setClientToDelete(null);
        });
    };

    const openClientHistoryModal = async (clientId) => {
        setSelectedClient(clients.find(client => client.id === clientId));
        setShowClientHistoryModal(true);
    
        try {
            const responseEvents = await fetch('/get_events', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ client_id: clientId })
            });
            const dataEvents = await responseEvents.json();
            setEventHistory(dataEvents.events);
    
            const responseSales = await fetch('/get_client_sales', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ client_id: clientId })
            });
            const dataSales = await responseSales.json();
            setSalesHistory(dataSales);
    
            const responseUserHistory = await fetch('/get_client_history', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ client_id: clientId })
            });
            const dataUserHistory = await responseUserHistory.json();
            setUserHistory(dataUserHistory);
    
            const responseClientInfo = await fetch('/get_client_info', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ client_id: clientId })
            });
            const dataClientInfo = await responseClientInfo.json();
            if (dataClientInfo.success) {
                setClientInfo(dataClientInfo.client_info);
                setContactsInfo(dataClientInfo.contacts_info);
            } else {
                toastr.error(dataClientInfo.message);
            }
        } catch (error) {
            console.error('Error fetching client info:', error);
            toastr.error('Error inesperado al obtener la información del cliente.');
        }
    };
    

    useEffect(() => {
        console.log('Contacts:', contacts);
        console.log('Selected Client:', selectedClient);
    }, [contacts, selectedClient]);

    const handleAddEvent = () => {
        const timestamp = new Date().toISOString();
        fetch('/add_event', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                client_id: selectedClient.id,
                event_description: eventDescription,
                timestamp: timestamp
            })
        })
        .then(response => response.json())
        .then(data => {
            toastr.success(data.message);
            setEventDescription('');
            openClientHistoryModal(selectedClient.id);
        })
        .catch(error => console.error('Error adding event:', error));
    };

    const handleEditEvent = async (eventId, newDescription) => {
        const response = await fetch('/edit_event', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                event_id: eventId,
                new_description: newDescription
            })
        });
        const data = await response.json();
        toastr.success(data.message);
        openClientHistoryModal(selectedClient.id);
        setEditEventDescription({});
    };

    const handleDeleteEvent = async (eventId) => {
        const confirmation = window.confirm('¿Estás seguro de que deseas eliminar este evento?');
        if (confirmation) {
            const response = await fetch('/delete_event', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ event_id: eventId })
            });
            const data = await response.json();
            toastr.success(data.message);
            openClientHistoryModal(selectedClient.id);
        }
    };

    const handleSendEmail = () => {
        const formData = new FormData();
        formData.append('contact_id', selectedContactId);
        formData.append('cc', emailFormData.cc);
        formData.append('subject', emailFormData.subject);
        formData.append('body', emailFormData.body);

        uploadedFiles.forEach(file => {
            formData.append('attachments', new File([file.path], file.name));
        });

        fetch('/send_email', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            toastr.success(data.message);
            setShowSendEmailModal(false);
        })
        .catch(error => console.error('Error sending email:', error));
    };

    const handleSendWhatsapp = () => {
        const selectedContact = contacts[selectedClient.id]?.find(contact => contact.id === parseInt(selectedWhatsappContactId));
        const phoneNumber = selectedContact ? selectedContact.number : '';

        if (phoneNumber) {
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(whatsappMessage)}`;
            window.open(whatsappUrl, '_blank');
            setShowWhatsappModal(false);
        } else {
            toastr.error('Seleccione un contacto válido');
        }
    };

    const handleScheduleMeeting = () => {
        const selectedContact = contacts[selectedClient.id]?.find(contact => contact.id === selectedMeetingContactId);
        const emails = selectedContact ? [selectedContact.email, ...scheduleMeetingData.invitees] : scheduleMeetingData.invitees;
    
        fetch('/schedule_meeting', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                emails: emails,
                date: scheduleMeetingData.date,
                time: scheduleMeetingData.time,
                title: scheduleMeetingData.title,
                client_id: selectedClient.id  // Enviar el ID del cliente
            })
        })
        .then(response => response.json())
        .then(data => {
            toastr.success(data.message);
            setShowScheduleMeetingModal(false);
        })
        .catch(error => console.error('Error scheduling meeting:', error));
    };
    

    const handleTakeClient = (clientId) => {
        const client = clients.find(client => client.id === clientId);
        fetch('/take_client', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ client_id: clientId })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                toastr.success('Cliente tomado con éxito');
                setClients(clients.map(client =>
                    client.id === clientId ? { ...client, responsable: data.responsable, color: 'green', has_taken: true } : client
                ));
            } else {
                toastr.error('Error al tomar el cliente');
            }
        })
        .catch(error => console.error('Error taking client:', error));
    };

    const handleSaveEmailTemplate = () => {
        const name = prompt("Por favor, introduce un nombre para la plantilla de correo:");
        if (!name) return;
        const content = emailFormData.body;

        fetch('/save_email_template', {
            method: 'POST',
            body: new URLSearchParams({ 'name': name, 'content': content }),
        })
        .then(response => response.json())
        .then(data => {
            toastr.success(data.message);
        });
    };

    const handleLoadEmailTemplate = () => {
        fetch('/get_template_names')
        .then(response => response.json())
        .then(data => {
            setEmailTemplates(data.names);
            setShowSelectTemplateModal(true);
        });
    };

    const handleChooseEmailTemplate = (name) => {
        fetch(`/load_email_template?name=${name}`)
        .then(response => response.json())
        .then(data => {
            if (data.content) {
                setEmailFormData({ ...emailFormData, body: data.content });
                setShowSelectTemplateModal(false);
            } else {
                toastr.error(data.message);
            }
        });
    };

    const handleSaveWhatsappTemplate = () => {
        const name = prompt("Por favor, introduce un nombre para la plantilla de WhatsApp:");
        if (!name) return;
        const content = whatsappMessage;

        fetch('/save_whatsapp_template', {
            method: 'POST',
            body: new URLSearchParams({ 'name': name, 'content': content }),
        })
        .then(response => response.json())
        .then(data => {
            toastr.success(data.message);
        });
    };

    const handleLoadWhatsappTemplate = () => {
        fetch('/get_whatsapp_template_names')
        .then(response => response.json())
        .then(data => {
            setWhatsappTemplates(data.names);
            setShowSelectWhatsappTemplateModal(true);
        });
    };

    const handleChooseWhatsappTemplate = (name) => {
        fetch(`/load_whatsapp_template?name=${name}`)
        .then(response => response.json())
        .then(data => {
            if (data.content) {
                setWhatsappMessage(data.content);
                setShowSelectWhatsappTemplateModal(false);
            } else {
                toastr.error(data.message);
            }
        });
    };

    const formatTimestamp = (timestamp) => {
        return format(new Date(timestamp), 'dd/MM/yyyy HH:mm:ss');
    };

    const handleSaleDoubleClick = (saleId) => {
        const sale = sales.find(sale => sale.sale_id === saleId);
        if (sale) {
            setSaleDetails(sale);
            setShowSaleDetailsModal(true);
        }
    };

    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files);
    
        for (const file of files) {
            const formData = new FormData();
            formData.append('emailAttachment', file);
    
            try {
                const response = await fetch('/upload_email_attachment', {
                    method: 'POST',
                    body: formData
                });
                const data = await response.json();
                if (data.message === 'File uploaded successfully') {
                    setUploadedFiles(prevFiles => [
                        ...prevFiles, 
                        { name: data.filename, path: data.file_path }
                    ]);
                } else {
                    toastr.error('Error subiendo archivo: ' + data.message);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
                toastr.error('Error subiendo archivo.');
            }
        }
    
        // Limpiar el campo de archivo después de la subida
        document.getElementById('formFileInput').value = '';
    };
    
    

    const handleNewContactChange = (e) => {
        const { name, value } = e.target;
        setNewContact({ ...newContact, [name]: value });
    };

    

    const handleAddContact = (e) => {
        e.preventDefault();
        const contactData = { ...newContact, client_id: selectedClient.id };
        
        fetch('/add_contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(contactData)
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                toastr.success(data.message);
                setShowAddContactModal(false);
                openClientHistoryModal(selectedClient.id); // Refresh client info
            } else {
                toastr.error(data.message);
            }
        })
        .catch(error => toastr.error('Error inesperado al agregar el contacto.'));
    };
    


    const handleDeleteContact = (contactId) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este contacto?')) {
            fetch('/delete_contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ contact_id: contactId })
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    toastr.success(data.message);
                    setContactsInfo(contactsInfo.filter(contact => contact.contact_id !== contactId));
                } else {
                    toastr.error(data.message);
                }
            })
            .catch(error => toastr.error('Error inesperado al eliminar el contacto.'));
        }
    };


    
    useEffect(() => {
        // Fetch user data and set isAdmin state
        fetch('/get_all_users')
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setUsers(data.users);
                }
            })
            .catch(error => console.error('Error fetching users:', error));

        // Suscripción a eventos de socket
        socket.on('update_client_responsable', ({ client_id, responsable, user_history }) => {
            setClients(clients => clients.map(client =>
                client.id === client_id ? { ...client, Responsable: responsable } : client
            ));
            if (selectedClient.id === client_id) {
                setUserHistory(user_history);
            }
        });

        return () => {
            socket.off('update_client_responsable');
        };
    }, [selectedClient]);

    const handleAssignResponsable = () => {
        fetch('/assign_client_responsable', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                client_id: selectedClient.id,
                responsable_email: responsableEmail
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                toastr.success(data.message);
                setShowAssignResponsableModal(false);
                setClients(clients.map(client =>
                    client.id === selectedClient.id ? { ...client, Responsable: responsableEmail } : client
                ));
                setUserHistory([...userHistory, { user_email: responsableEmail, taken_on: new Date().toISOString() }]);
                // Emitir un evento de socket
                socket.emit('update_client_responsable', { client_id: selectedClient.id, responsable: responsableEmail });
            } else {
                toastr.error(data.message);
            }
        })
        .catch(error => toastr.error('Error inesperado al asignar el responsable.'));
    };

    const handleFileDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        setUploadedFiles([...uploadedFiles, ...files]);
    };
    
    const handleRemoveFile = (index) => {
        const newFiles = uploadedFiles.filter((_, i) => i !== index);
        setUploadedFiles(newFiles);
    };
    

    const renderTabContent = () => {
        switch (activeTab) {
             

        default:
            return null;
        }
    };

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-12">
                <div className="clients-table-container">
            <input
                type="text"
                placeholder="Buscar clientes..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="form-control mb-3"
            />
                        <div className="clients-table-section">
                            {clients.map(client => {
                                const clientContacts = contacts[client.id] || [];
                                const primaryContact = clientContacts[0] || {};
                                return (
                                    <div key={client.id} className={`clients-table-card ${client.color}`}
                                        onDoubleClick={() => openClientHistoryModal(client.id)}>
                                        <div className="clients-table-card-header">
                                            <div>
                                                <h4 className="clients-table-card-title">{client.company_name}</h4>
                                                <p className="clients-table-card-subtitle">{primaryContact.contact_name || 'N/A'}</p>
                                            </div>
                                            <div className="clients-table-info">
                                                <p><span>Email:</span> {primaryContact.email || 'N/A'}</p>
                                                <p><span>Posición:</span> {primaryContact.position || 'N/A'}</p>
                                                <p><span>Número:</span> {primaryContact.number || 'N/A'}</p>
                                                <p><span>Descripción:</span> {client.description}</p>
                                                <p><span>Responsable:</span> {client.responsable}</p>
                                            </div>
                                        </div>
                                        <div className="clients-table-card-body">
                                            <div className="clients-table-actions-container">
                                                <Button variant="info" onClick={() => { setSelectedClient(client); setShowSendEmailModal(true); }}>Email</Button>
                                                <Button variant="success" onClick={() => { setSelectedClient(client); setShowScheduleMeetingModal(true); }}>Reunión</Button>
                                                <Button variant="warning" onClick={() => { setSelectedClient(client); setShowWhatsappModal(true); }}>WhatsApp</Button>
                                                {client.color === 'red' && !client.has_taken && (
                                                    <Button variant="primary" onClick={() => handleTakeClient(client.id)}>Tomar</Button>
                                                )}
                                                <Button variant="danger" onClick={() => requestDeleteClient(client)}>Eliminar</Button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <Button onClick={() => setShowAddClientModal(true)} className="bg-purple-600 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded mt-4">
                    Agregar Cliente
                </Button>
            </div>

            {/* Add Client Modal */}
            <Modal className="modal-dark add-client-modal" show={showAddClientModal} onHide={() => setShowAddClientModal(false)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Agregar Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleAddClient}>
                        <Form.Group controlId="formCompanyName">
                            <Form.Label>Nombre Empresa</Form.Label>
                            <Form.Control
                                type="text"
                                name="company_name"
                                value={formData.company_name}
                                onChange={handleInputChange}
                                required />
                        </Form.Group>
                        <Form.Group controlId="formDescription">
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                rows={3} />
                        </Form.Group>
                        {formData.contacts.map((contact, index) => (
                            <div key={index} className="contact-entry">
                                <div className="contact-fields">
                                    <Form.Group controlId={`formContactName${index}`}>
                                        <Form.Label>Nombre Contacto</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="contact_name"
                                            value={contact.contact_name}
                                            onChange={(e) => handleContactChange(index, e)}
                                            required />
                                    </Form.Group>
                                    <Form.Group controlId={`formNumber${index}`}>
                                        <Form.Label>Número</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="number"
                                            value={contact.number}
                                            onChange={(e) => handleContactChange(index, e)} />
                                    </Form.Group>
                                    <Form.Group controlId={`formEmail${index}`}>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={contact.email}
                                            onChange={(e) => handleContactChange(index, e)}
                                            required />
                                    </Form.Group>
                                    <Form.Group controlId={`formPosition${index}`}>
                                        <Form.Label>Cargo</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="position"
                                            value={contact.position}
                                            onChange={(e) => handleContactChange(index, e)} />
                                    </Form.Group>
                                </div>
                                {index > 0 && (
                                    <Button variant="danger" className="remove-contact-button" onClick={() => removeContact(index)}>
                                        x
                                    </Button>
                                )}
                            </div>
                        ))}
                        <Button variant="secondary" onClick={addContact}>Agregar Otro Contacto</Button>
                        <Button variant="primary" type="submit">Agregar Cliente</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Client History Modal */}
<Modal className="modal-dark client-history-modal" show={showClientHistoryModal} onHide={() => setShowClientHistoryModal(false)}>
    <Modal.Header className="modal-header" closeButton>
        <Modal.Title>Historial del Cliente</Modal.Title>
    </Modal.Header>
    <Modal.Body className="client-history-modal-body">
        <div className="client-history-modal-tabs">
            <div className={`client-history-modal-tab ${activeTab === 'events' ? 'active' : ''}`} onClick={() => setActiveTab('events')}>Eventos</div>
            <div className={`client-history-modal-tab ${activeTab === 'sales' ? 'active' : ''}`} onClick={() => setActiveTab('sales')}>Ventas</div>
            <div className={`client-history-modal-tab ${activeTab === 'users' ? 'active' : ''}`} onClick={() => setActiveTab('users')}>Historial de Usuarios</div>
            <div className={`client-history-modal-tab ${activeTab === 'client_info' ? 'active' : ''}`} onClick={() => setActiveTab('client_info')}>Información del Cliente</div>
        </div>
        <div className="client-history-modal-body-content">
            {renderTabContent()}
            {activeTab === 'events' && (
    <>
        {/* Nueva Sección de Eventos */}
        <div className="client-history-modal-event-section">
            <div className="client-history-modal-section-header events-section-header">Eventos</div>
            {eventHistory.length === 0 ? (
                <p>No hay eventos registrados para este cliente.</p>
            ) : (
                <div className="clients-table-event-cards-container">
                    {eventHistory.map(event => (
                        <div key={event.id} className="clients-table-event-card">
                            <div className="clients-table-event-card-header">
                                <span className="clients-table-event-description">{event.event_description}</span>
                            </div>
                            <div className="clients-table-event-card-body">
                                <div className="clients-table-event-details">
                                    <small className="clients-table-event-timestamp">{formatTimestamp(event.timestamp)}</small>
                                    <small className="clients-table-event-user">{event.user}</small>
                                </div>
                                {event.is_editable && isAdmin && (
                                    <div className="clients-table-event-actions">
                                        {editEventDescription[event.id] ? (
                                            <>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={5}  // Puedes ajustar este valor para hacer el cuadro más grande
                                                    value={editEventDescription[event.id]}
                                                    onChange={(e) => setEditEventDescription({ ...editEventDescription, [event.id]: e.target.value })}
                                                />
                                                <div className="edit-buttons-container">
                                                    <Button variant="success" onClick={() => handleEditEvent(event.id, editEventDescription[event.id])}>Guardar</Button>
                                                    <Button variant="danger" onClick={() => handleDeleteEvent(event.id)}>Eliminar</Button>
                                                </div>
                                            </>
                                        ) : (
                                            <Button variant="warning" onClick={() => setEditEventDescription({ ...editEventDescription, [event.id]: event.event_description })}>Editar</Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>

        {/* Sección para agregar nuevos eventos */}
        <div className="client-history-modal-add-event-section">
            <Form>
                <div className="client-history-modal-section-header">Agregar Evento</div>
                <Form.Group controlId="eventDescription">
                    <Form.Label>Descripción del Evento:</Form.Label>
                    <Form.Control
                        as="textarea"
                        value={eventDescription}
                        onChange={(e) => setEventDescription(e.target.value)}
                        rows={3} />
                </Form.Group>
                <Button variant="primary" onClick={handleAddEvent}>Agregar Evento</Button>
            </Form>
        </div>
    </>
)}

    {activeTab === 'users' && (
        <div className="client-history-modal-user-history-section">
            <div className="client-history-modal-section-header users-section-header">Historial de Usuarios</div>
            {userHistory.length === 0 ? (
                <p>No hay historial de usuarios para este cliente.</p>
            ) : (
                <div className="clients-table-user-history-container">
                    {userHistory.map(record => (
                        <div key={record.user_email} className="clients-table-user-history-card">
                            <div className="clients-table-user-history-header">
                                <span className="clients-table-user-email">{record.user_email}</span>
                            </div>
                            <div className="clients-table-user-history-body">
                                <small className="clients-table-taken-on">Tomado el {record.taken_on}</small>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {isAdmin && (
                <Button variant="info" onClick={() => setShowAssignResponsableModal(true)}>
                    Asignar Responsable
                </Button>
            )}
        </div>
    )}
    {activeTab === 'client_info' && (
    <div className="client-info-section">
        <div className="client-history-modal-section-header client-info-section-header">Información del Cliente</div>
        {isAdmin && isEditingClientInfo ? (
            <Form>
                <Form.Group controlId="formCompanyName">
                    <Form.Label>Nombre Empresa:</Form.Label>
                    <Form.Control
                        type="text"
                        name="company_name"
                        value={editClientInfo.company_name || clientInfo.company_name}
                        onChange={handleEditClientInfo}
                        readOnly={!isEditingClientInfo}
                    />
                </Form.Group>
                <Form.Group controlId="formDescription">
                    <Form.Label>Descripción:</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="description"
                        value={editClientInfo.description || clientInfo.description}
                        onChange={handleEditClientInfo}
                        rows={3}
                        readOnly={!isEditingClientInfo}
                    />
                </Form.Group>
                <Form.Group controlId="formResponsable">
                    <Form.Label>Responsable:</Form.Label>
                    <Form.Control
                        type="text"
                        name="responsable"
                        value={clientInfo.responsable}
                        readOnly
                    />
                </Form.Group>
                <Form.Group controlId="formStatus">
                    <Form.Label>Estado:</Form.Label>
                    <Form.Control
                        type="text"
                        name="status"
                        value={clientInfo.status}
                        readOnly
                    />
                </Form.Group>
                <Form.Group controlId="formActiveSince">
                    <Form.Label>Activo Desde:</Form.Label>
                    <Form.Control
                        type="text"
                        name="active_since"
                        value={clientInfo.active_since}
                        readOnly
                    />
                </Form.Group>

                {/* Nuevo fragmento agregado para la sección de Contactos */}
                <div className="contacts-info-section">
                    <h5>Contactos</h5>
                    {contactsInfo.length > 0 ? (
                        contactsInfo.map((contact, index) => (
                            <div key={index} className="contact-card">
                                <Form.Group controlId={`formContactName${index}`}>
                                    <Form.Label>Nombre Contacto:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={contact.contact_name}
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group controlId={`formNumber${index}`}>
                                    <Form.Label>Número:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={contact.number}
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group controlId={`formEmail${index}`}>
                                    <Form.Label>Email:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={contact.email}
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group controlId={`formPosition${index}`}>
                                    <Form.Label>Cargo:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={contact.position}
                                        readOnly
                                    />
                                </Form.Group>
                                <Button variant="warning" onClick={() => handleEditContact(contact)}>Editar</Button>
                                <Button variant="danger" onClick={() => handleDeleteContact(contact.contact_id)}>Eliminar</Button>
                            </div>
                        ))
                    ) : (
                        <p>No se encontraron contactos para este cliente.</p>
                    )}
                    <Button variant="secondary" onClick={() => setShowAddContactModal(true)}>Agregar Contacto</Button>
                </div>
                {/* Fin del nuevo fragmento */}

                <div className="edit-buttons">
                    <Button variant="secondary" onClick={() => setIsEditingClientInfo(!isEditingClientInfo)}>
                        {isEditingClientInfo ? 'Cancelar' : 'Editar'}
                    </Button>
                    {isEditingClientInfo && (
                        <Button variant="primary" onClick={saveClientInfo}>
                            Guardar Cambios
                        </Button>
                    )}
                </div>
            </Form>
        ) : (
            <div>
                <div className="client-info-section">
                    <h5>Datos del Cliente</h5>
                    <p><span>Nombre de la Empresa:</span> {clientInfo.company_name}</p>
                    <p><span>Descripción:</span> {clientInfo.description}</p>
                    <p><span>Responsable:</span> {clientInfo.responsable}</p>
                    <p><span>Estado:</span> {clientInfo.status}</p>
                    <p><span>Activo Desde:</span> {clientInfo.active_since}</p>
                </div>
                <div className="contacts-info-section">
                    <h5>Contactos</h5>
                    {contactsInfo.map((contact, index) => (
                        <div key={index} className="contact-card">
                            <p><span>Nombre Contacto:</span> {contact.contact_name}</p>
                            <p><span>Número:</span> {contact.number}</p>
                            <p><span>Email:</span> {contact.email}</p>
                            <p><span>Cargo:</span> {contact.position}</p>
                        </div>
                    ))}
                </div>
                {isAdmin && (
                    <div className="edit-buttons">
                        <Button variant="secondary" onClick={() => setIsEditingClientInfo(true)}>
                            Editar
                        </Button>
                    </div>
                    
                )}
            </div>
        )}
    </div>
)}

{activeTab === 'sales' && (
    <div className="sales-cards-container">
        <div className="client-history-modal-section-header sales-section-header">Historial de Ventas</div>
        {salesHistory.length === 0 ? (
            <p>No hay ventas registradas para este cliente.</p>
        ) : (
            <div className="sales-cards-container">
                {salesHistory.map(sale => {
                    const saleDate = new Date(sale.created_at);
                    const formattedDate = isNaN(saleDate) ? 'Fecha no válida' : formatTimestamp(saleDate);

                        return (
                            <div key={sale.sale_id} className="sales-card" onDoubleClick={() => handleSaleDoubleClick(sale.sale_id)}>
                                <div className="sales-card-header">
                                    <h5>{sale.service}</h5>
                                    <span className="sales-stage">{sale.stage}</span>
                                </div>
                                <div className="sales-card-body">
                                    <p><strong>Solicitud:</strong> {sale.solicitud}</p>
                                    <p><strong>Fecha:</strong> {formattedDate}</p>
                                    <p><strong>Observaciones:</strong> {sale.observaciones}</p>
                                    <p><strong>Responsable:</strong> {sale.responsable}</p>
                                    <p><strong>Monto de Venta:</strong> {sale.sale_amount}</p>
                                    <p><strong>Total a Facturar:</strong> {sale.total_to_invoice}</p>
                                    <p><strong>Nombre del Candidato:</strong> {sale.candidate_name}</p>
                                    <p><strong>Tipo de Contrato:</strong> {sale.contract_type}</p>
                                    <p><strong>Modalidad de Trabajo:</strong> {sale.work_mode}</p>
                                    <p><strong>Estado:</strong> {sale.status}</p>
                                    <p><strong>Fecha de Cierre:</strong> {sale.closed_at ? formatTimestamp(new Date(sale.closed_at)) : 'N/A'}</p>
                                    <p><strong>Proyección de Facturación:</strong> {sale.billing_projection}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    )}
</div>

                </Modal.Body>
            </Modal>

            {/* Send Email Modal */}
            <Modal className="modal-dark send-email-modal" show={showSendEmailModal} onHide={() => setShowSendEmailModal(false)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Enviar Correo</Modal.Title>
                </Modal.Header>
                <Modal.Body className="send-email-modal-body">
                    <Form>
                        <Form.Group controlId="formContact">
                            <Form.Label>Seleccionar Contacto:</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedContactId}
                                onChange={(e) => setSelectedContactId(e.target.value)}
                                required
                            >
                                <option value="">Seleccione un contacto</option>
                                {contacts[selectedClient.id]?.map(contact => (
                                    <option key={contact.id} value={contact.id}>
                                        {contact.contact_name} ({contact.email})
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formCC">
                            <Form.Label>Correos en copia:</Form.Label>
                            <Form.Control
                                type="text"
                                value={emailFormData.cc}
                                onChange={(e) => setEmailFormData({ ...emailFormData, cc: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formSubject">
                            <Form.Label>Asunto:</Form.Label>
                            <Form.Control
                                type="text"
                                value={emailFormData.subject}
                                onChange={(e) => setEmailFormData({ ...emailFormData, subject: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formBody">
                            <Form.Label>Cuerpo del correo:</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={emailFormData.body}
                                onChange={(e) => setEmailFormData({ ...emailFormData, body: e.target.value })}
                                rows={4}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formAttachments">
    <Form.Label>Adjuntar Archivos:</Form.Label>
    <div 
        onDrop={handleFileDrop} 
        onDragOver={(e) => e.preventDefault()} 
        onClick={() => document.getElementById('formFileInput').click()}
        className="dropzone"
    >
        <p>Arrastra y suelta los archivos aquí, o haz clic para seleccionar archivos.</p>
        <input
            id="formFileInput"
            type="file"
            onChange={handleFileChange}
            multiple
            style={{ display: 'none' }}
        />
    </div>
    <ul>
        {uploadedFiles.map((file, index) => (
            <li key={index}>
                {file.name}
                <Button variant="danger" onClick={() => handleRemoveFile(index)}>Eliminar</Button>
            </li>
        ))}
    </ul>
</Form.Group>

                        <Button variant="primary" onClick={handleSendEmail}>Enviar</Button>
                        <Button variant="secondary" onClick={handleSaveEmailTemplate}>Guardar Plantilla</Button>
                        <Button variant="secondary" onClick={handleLoadEmailTemplate}>Cargar Plantilla</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Select Template Modal */}
            <Modal className="modal-dark select-template-modal" show={showSelectTemplateModal} onHide={() => setShowSelectTemplateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Seleccionar Plantilla de Correo</Modal.Title>
                </Modal.Header>
                <Modal.Body className="select-template-modal-body">
                    <ul>
                        {emailTemplates.map(template => (
                            <li key={template} onClick={() => handleChooseEmailTemplate(template)}>
                                {template}
                            </li>
                        ))}
                    </ul>
                </Modal.Body>
            </Modal>

            {/* WhatsApp Modal */}
            <Modal className="modal-dark whatsapp-modal" show={showWhatsappModal} onHide={() => setShowWhatsappModal(false)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Enviar WhatsApp</Modal.Title>
                </Modal.Header>
                <Modal.Body className="whatsapp-modal-body">
                    <Form>
                        <Form.Group controlId="formContact">
                            <Form.Label>Seleccionar Contacto:</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedWhatsappContactId}
                                onChange={(e) => setSelectedWhatsappContactId(e.target.value)}
                                required
                            >
                                <option value="">Seleccione un contacto</option>
                                {contacts[selectedClient.id]?.map(contact => (
                                    <option key={contact.id} value={contact.id}>
                                        {contact.contact_name} ({contact.number})
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="whatsappMessage">
                            <Form.Label>Mensaje:</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={whatsappMessage}
                                onChange={(e) => setWhatsappMessage(e.target.value)}
                                rows={4}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" onClick={handleSendWhatsapp}>Enviar</Button>
                        <Button variant="secondary" onClick={handleSaveWhatsappTemplate}>Guardar Plantilla</Button>
                        <Button variant="secondary" onClick={handleLoadWhatsappTemplate}>Cargar Plantilla</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Select WhatsApp Template Modal */}
            <Modal className="modal-dark select-whatsapp-template-modal" show={showSelectWhatsappTemplateModal} onHide={() => setShowSelectWhatsappTemplateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Seleccionar Plantilla de WhatsApp</Modal.Title>
                </Modal.Header>
                <Modal.Body className="select-whatsapp-template-modal-body">
                    <ul>
                        {whatsappTemplates.map(template => (
                            <li key={template} onClick={() => handleChooseWhatsappTemplate(template)}>
                                {template}
                            </li>
                        ))}
                    </ul>
                </Modal.Body>
            </Modal>

            {/* Schedule Meeting Modal */}
            <Modal className="modal-dark schedule-meeting-modal" show={showScheduleMeetingModal} onHide={() => setShowScheduleMeetingModal(false)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Programar Reunión</Modal.Title>
                </Modal.Header>
                <Modal.Body className="schedule-meeting-modal-body">
                    <Form>
                        <Form.Group controlId="formContact">
                            <Form.Label>Seleccionar Contacto:</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedMeetingContactId}
                                onChange={(e) => setSelectedMeetingContactId(e.target.value)}
                                required
                            >
                                <option value="">Seleccione un contacto</option>
                                {contacts[selectedClient.id]?.map(contact => (
                                    <option key={contact.id} value={contact.id}>
                                        {contact.contact_name} ({contact.email})
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="meetingDate">
                            <Form.Label>Fecha:</Form.Label>
                            <Form.Control
                                type="date"
                                value={scheduleMeetingData.date}
                                onChange={(e) => setScheduleMeetingData({ ...scheduleMeetingData, date: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="meetingTime">
                            <Form.Label>Hora:</Form.Label>
                            <Form.Control
                                type="time"
                                value={scheduleMeetingData.time}
                                onChange={(e) => setScheduleMeetingData({ ...scheduleMeetingData, time: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="meetingTitle">
                            <Form.Label>Título de la Reunión:</Form.Label>
                            <Form.Control
                                type="text"
                                value={scheduleMeetingData.title}
                                onChange={(e) => setScheduleMeetingData({ ...scheduleMeetingData, title: e.target.value })}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="meetingInvitees">
                            <Form.Label>Invitados:</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="email@ejemplo.com"
                                value={scheduleMeetingData.invitees.join(', ')}
                                onChange={(e) => setScheduleMeetingData({ ...scheduleMeetingData, invitees: e.target.value.split(', ') })}
                            />
                        </Form.Group>
                        <Button variant="primary" onClick={handleScheduleMeeting}>Programar</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Delete Confirm Modal */}
            <Modal className="modal-dark delete-confirm-modal" show={showDeleteConfirmModal} onHide={() => setShowDeleteConfirmModal(false)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body className="delete-confirm-modal-body">
                    <p>¿Estás seguro de que deseas eliminar al cliente {clientToDelete?.company_name}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmModal(false)}>Cancelar</Button>
                    <Button variant="danger" onClick={handleDeleteClient}>Eliminar</Button>
                </Modal.Footer>
            </Modal>



            <Modal show={showEditContactModal} onHide={() => setShowEditContactModal(false)} backdrop="static">
    <Modal.Header closeButton>
        <Modal.Title>Editar Contacto</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Form>
            <Form.Group controlId="editContactName">
                <Form.Label>Nombre Contacto:</Form.Label>
                <Form.Control
                    type="text"
                    name="contact_name"
                    value={currentContact.contact_name}
                    onChange={(e) => setCurrentContact({ ...currentContact, contact_name: e.target.value })}
                />
            </Form.Group>
            <Form.Group controlId="editContactNumber">
                <Form.Label>Número:</Form.Label>
                <Form.Control
                    type="text"
                    name="number"
                    value={currentContact.number}
                    onChange={(e) => setCurrentContact({ ...currentContact, number: e.target.value })}
                />
            </Form.Group>
            <Form.Group controlId="editContactEmail">
                <Form.Label>Email:</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    value={currentContact.email}
                    onChange={(e) => setCurrentContact({ ...currentContact, email: e.target.value })}
                />
            </Form.Group>
            <Form.Group controlId="editContactPosition">
                <Form.Label>Cargo:</Form.Label>
                <Form.Control
                    type="text"
                    name="position"
                    value={currentContact.position}
                    onChange={(e) => setCurrentContact({ ...currentContact, position: e.target.value })}
                />
            </Form.Group>
        </Form>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowEditContactModal(false)}>Cancelar</Button>
        <Button variant="primary" onClick={saveContactInfo}>Guardar Cambios</Button>
    </Modal.Footer>
</Modal>

<Modal show={showAddContactModal} onHide={() => setShowAddContactModal(false)} className="add-contact-modal" backdrop="static">
    <Modal.Header closeButton>
        <Modal.Title>Agregar Contacto</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Form onSubmit={handleAddContact}>
            <Form.Group controlId="newContactName">
                <Form.Label>Nombre Contacto</Form.Label>
                <Form.Control
                    type="text"
                    name="contact_name"
                    value={newContact.contact_name}
                    onChange={handleNewContactChange}
                    required
                />
            </Form.Group>
            <Form.Group controlId="newContactNumber">
                <Form.Label>Número</Form.Label>
                <Form.Control
                    type="text"
                    name="number"
                    value={newContact.number}
                    onChange={handleNewContactChange}
                />
            </Form.Group>
            <Form.Group controlId="newContactEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    value={newContact.email}
                    onChange={handleNewContactChange}
                    required
                />
            </Form.Group>
            <Form.Group controlId="newContactPosition">
                <Form.Label>Cargo</Form.Label>
                <Form.Control
                    type="text"
                    name="position"
                    value={newContact.position}
                    onChange={handleNewContactChange}
                />
            </Form.Group>
            <Button variant="primary" type="submit">Agregar Contacto</Button>
        </Form>
    </Modal.Body>
</Modal>


<Modal show={showAssignResponsableModal} onHide={() => setShowAssignResponsableModal(false)} className="assign-responsable-modal" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Asignar Responsable</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formResponsableEmail">
                            <Form.Label>Seleccionar Responsable</Form.Label>
                            <Form.Control
                                as="select"
                                value={responsableEmail}
                                onChange={(e) => setResponsableEmail(e.target.value)}
                                required
                            >
                                <option value="">Seleccione un responsable</option>
                                {users.map(user => (
                                    <option key={user.id} value={user.format}>
                                        {user.format}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Button variant="primary" onClick={handleAssignResponsable}>Asignar</Button>
                    </Form>
                </Modal.Body>
            </Modal>

        </div>
    );
};

export default ClientsTable;
